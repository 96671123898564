.user-merchants {
  width: 534px;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 45px;
  }
  &__right-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;


    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 275px;
    }
  }
  &__input-wrap {
   position: relative; 
   .ant-input-number-input {
     padding-right: 40px;
   }
  }
  &__save {
    all: unset;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer;
    svg {
      display: block;
    }
  }
  &__loader {
    display: block;
    margin: 20px auto 0;
  }
}

.user-merchants-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 40px;
}