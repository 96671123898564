.network-filter {
  margin-right: 20px;
  &__dropdown {
    width: 100%;
    background: #fff;
    padding: 16px;
    border: 1px solid #d3dce6;
    border-radius: 3px;
    .ant-checkbox-group,
    .ant-radio-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item:not(:first-of-type),
      .ant-radio-wrapper:not(:first-of-type) {
        margin-top: 12px;
      }
    }
    .ant-radio-wrapper {
      display: block;
      font-weight: 600;
      .ant-radio-inner {
        border-color: #4D7F70;
        &:after {
          background-color: #4D7F70;
        }
      }
      &.ant-radio-wrapper-checked {
        .ant-radio-inner {
          border-color: #4D7F70;
        }
      }
      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }
  &__button {
    display: flex;
    white-space: nowrap;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    padding: 9px 29px;
    color: #4D7F70;
    font-size: 14px;
    text-transform: capitalize;
    &-icon {
      transition: all 0.3s ease;
      &_active {
        transform: rotateZ(180deg);
      }
    }
  }
}
