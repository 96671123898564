.withdraw-mode-toggler-wrapper {
  @media screen and (max-width: 768px) {
    text-align: center;
  }  
}

.withdraw-mode-toggler {
  display: inline-block;
  padding: 5px 4px;
  background: #F3F4F5;
  border-radius: 6px;
  &__item {
    display: inline-block;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #818A98;
    padding: 7px 29px;
    border-radius: 4px;
    cursor: pointer;
    &.active {
      color: #4D7F70;
      background: #FFF;
    }
  }
}