.multisend-form {
  &__textarea-wrap {
    margin-top: 30px;
  }
  &__textarea-header,
  &__textarea-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__textarea-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }
  &__file-switcher {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #3B4857;
    cursor: pointer;
  }
  &__files {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    border-radius: 10px;
    cursor: pointer;
    input {
      display: none;
    }
    &-tip {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #3B4857;
      margin-top: 20px;
    }
  }
  &__textarea-tip {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #818A98;
  }
  &__textarea-examples {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #818A98;
    cursor: pointer;
  }
  &__textarea {
    display: flex;
    position: relative;
    width: 100%;
    height: 180px;
    margin: 10px auto 10px;
    border: 1px solid #E0E6ED;
    border-radius: 10px;
    textarea, ul {
      all: unset;
      height: 100%;
      box-sizing: border-box;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }
    textarea {
      width: 100%;
      background: transparent;
      color: #3B4857;
      padding: 16px 16px 16px 0;
    }
    ul {
      flex: 0 0 50px;
      padding: 16px 0 16px 50px;
      list-style: decimal;
      overflow: hidden;
      li {
        color: transparent;
        &::marker {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #818A98;
        }
      }
    }
  }
  &__duplicates {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #F99400;
    margin-top: 33px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    &-button {
      cursor: pointer;
      text-decoration: underline;
      @media screen and (max-width: 768px) {
        margin-top: 10px;
      }
    }
  }
  &__validation {
    display: block;
    border: 1px solid #F0B90B;
    border-radius: 10px;
    padding: 15px 22px;
    margin-top: 10px;
    &-item {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #3B4857;
      word-break: break-word;
      &:not(:first-of-type) {
        margin-top: 5px;
      }
      svg {
        margin-right: 12px;
        flex-shrink: 0;
      }
    }
  }
  &__button {
    padding: 20px 80px;
    margin: 30px 0 0 auto;
  }
}