.calendly {
  .button {
      display: flex;
      text-align: center;
      justify-content: center;
      width: 150px;
      background-color: #4D7F70;
      line-height: 17px;
      font-size: 14px;
      color: #ffffff;
      padding: 12.5px 26px;
      border-radius: 4px;
      outline: none;
      border: none;
      cursor: pointer;

      &-self-hosted{
          background-color: #7b61ff;
      }
  }
}