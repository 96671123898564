.swap-back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #679A8A;
  &__button {
    width: 790px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  svg {
    margin-right: 5px;
  }
}