.fee-wallets-list {
  position: relative;
  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    &-image {
      margin-top: 45px;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }
  &__list {
    margin-top: 20px;
  }
  &__wallet {
    position: relative;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #E0E6ED;
    transition: border-color .3s ease,
    background .3s ease;
    &:not(:first-of-type) {
      margin-top: 16px;
    }
    > .ant-row {
      padding: 0 20px;
      height: 84px;
      border-radius: 10px;
    }
    &_hasTokens {
      padding: 10px 10px 10px 40px;
      > .ant-row {
        padding: 0 10px;
        height: 62px;
      }
    }
  }
  &__dropdown-icon {
    position: absolute;
    top: 30px;
    left: 15px;
    svg {
      display: block;
      width: 24px;
      height: 24px;
    }
    &_opened {
      transform: rotateZ(180deg);
    }
  }
  &__logo {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  &__address {
    font-size: 12px;
  }
  &__balance {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &-usd {
      margin-top: 3px;
      font-size: 14px;
      color: #8492A5;
    }
    &-token {
      font-weight: 600;
      font-size: 16px;
      color: #3B4857;
    }
  }
  &__token {
    height: 62px;
    padding: 0 10px;
    border-radius: 11px;
    margin-top: 10px;
  }
  &__select {
    width: 188px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4D7F70;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    border: none;
    cursor: pointer;
    margin: 40px auto 0;
    &[disabled] {
      background: #B0BBCB;
      cursor: default;
    }
  }
  &__loader {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}