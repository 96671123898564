.transaction-filters {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  &__title {
    font-weight: 600;
    font-size: 16px;
    color: #000;
  }
  &__search, &__sort-wrap {
    @media screen and (max-width: 1023px) {
      width: 100%;
    }
  }
  &__search-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  &__currencies-button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 150px;
    height: 36px;
    background: transparent;
    border: 1px solid #d9d9d9;
    color: #273443;
    font-size: 12px;
    font-weight: normal;
    border-radius: 6px;
    cursor: pointer;
    transition: border-color 0.3s ease;
    @media screen and (max-width: 1023px) {
      justify-content: space-between;
      padding: 0 10px;
    }
    &:hover {
      background: inherit;
      // border-color: #40a9ff;
    }
    .anticon {
      margin-top: 2px;
    }
    &:disabled {
      display: none;
      background: red;
    }
  }
  &__search-field {
    min-width: 150px;
    height: 36px;
    position: relative;
    .ant-input-affix-wrapper {
      border-radius: 6px !important;
      &:hover {
        border-color: #d9d9d9;
      }
    }
    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
      border-color: #d9d9d9;
      box-shadow: none;
    }
    .ant-input-affix-wrapper-disabled {
      background: transparent;
    }
    .ant-input-suffix {
      margin-right: 32px;
      margin-top: 1px;
      .anticon-search {
        margin-left: 0;
      }
    }
    .ant-input-group-addon {
      width: auto;
      position: absolute;
      top: 2px;
      right: 1px;
      background: transparent;
      padding: 0;
      border: none;
    }
    .ant-input-search-icon:before {
      display: none;
    }
  }
  &__reset-button {
    width: 32px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:focus {
      background: none;
      box-shadow: none;
    }
    &:after {
      display: none;
    }
  }
  &__currencies-dropdown {
    width: 100%;
    background: #fff;
    padding: 16px;
    border: 1px solid #d3dce6;
    border-radius: 6px;
    .ant-radio-checked {
      &::after {
        border-color: #4D7F70;
      }
      .ant-radio-inner {
        border-color: #4D7F70;
        &:after {
          background: #4D7F70;
        }
      }
    } 
    .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
      border-color: #4D7F70;
    }
    .ant-checkbox-group,
    .ant-radio-group {
      display: flex;
      flex-direction: column;
      max-height: 340px;
      overflow-x: hidden;
      .ant-checkbox-group-item:not(:first-of-type),
      .ant-radio-wrapper:not(:first-of-type) {
        margin-top: 12px;
      }
    }
    .dropdown-divider {
      height: 1px;
      margin: 12px -17px 12px -17px;
      background: #d3dce6;
    }
  }
}
