.offer-item {
  display: flex;
  padding-left: 4px;
  justify-content: space-between;
  gap: 20px;
  > div {
    width: 25%
  }
  &:not(:first-of-type) {
    margin-top: 27px;
  }
  &__column {
    &:nth-last-of-type(1) {
      margin-top: 20px;
    }
    span {
      display: block;
    }
    &-name {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #9B9FB8;
    }
    &-value {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #3B4857;
      margin-top: 7px;
    }
  }
  &__rate {
    width: 125px;
    .offer-item__best {
      display: inline;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #679A8A;
    }
    .offer-item__column-value {
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 20px;
      cursor: pointer;
      &:hover {
        word-wrap: break-word;
      }
    }
  }
  &__eta {
    width: 150px;
  }
  &__partner {
    width: auto;
    .offer-item__column-value {
      display: flex;
      align-items: center;
      img {
        max-width: 16px;
        max-height: 16px;
        margin-right: 5px;
      }
    }
  }
  &__type {
    width: 125px;
    .offer-item__column-value {
      display: flex;
      align-items: center;
      svg {
        max-width: 16px;
        max-height: 16px;
        margin-right: 5px;
      }
    }
  }
  &__support-rate {
    width: 150px;
    .offer-item__column-value {
      margin-top: 15px;
    }
  }
  &__trust-score {
    width: auto;
  }
  &__exchange {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__support-rate-bar {
    width: 100px;
    height: 8px;
    background: #E9F3FE;
    border-radius: 28px;
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 28px;
      background: #679A8A;
    }
    &[data-score="0"]:after {
      width: 0;
    }
    &[data-score="1"]:after {
      width: calc(100% / 3);
    }
    &[data-score="2"]:after {
      width: calc(100% / 1.5);
    }
    &[data-score="3"]:after {
      width: 100%;
    }
  }
  &__exchange-button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 42px;
    border: 1px solid #D3DCE6;
    border-radius: 8px;
    transition: border .3s ease;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #679A8A;
    box-sizing: border-box;
    &:hover {
      border: 1px solid #679A8A;
    }
    &:disabled {
      border-color: #D3DCE6;
      color: #9B9FB8;
    }
  }
}

@media  (min-width:590px) and (max-width: 768px) {
  .offer-item {
    gap: 1px;
    > div {
     width: 25%;
    }
  }
}

@media screen and (max-width: 590px) {
  .offer-item-mobile {
    &__info {
      width: 100%;
    }
    &__row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      &:not(:first-of-type) {
        margin-top: 20px;
      }
    }
  }
  .offer-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    > div {
      width: 100%;
     }
    &:not(:first-of-type) {
      margin-top: 40px;
    }
    &__column {
      &-name,
      &-value {
        width: 120px;
      }
    }
    &__eta,
    &__partner,
    &__type,
    &__support-rate,
    &__trust-score,
    &__rate {
      width: auto;
    }
    &__rate {
      font-size: 16px;
    }
    &__exchange-button {
      color: #679A8A;
      width: 120px;
    }
  }
}