.ant-modal-body {
    padding: 30px;
}

.step-container {
    display: flex;
    flex-direction: column;
}

.main-step, .code-step {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

// start-step

.main-step {
    &__image {
        width: 100%;
        height: 100%;
         img {
            width: 100%;
            height: 100%;
         }
    }

    h4 {
        font-family: 'Inter';
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: left;
        color: #000000
    }

    p {
        font-family: 'Inter';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #575757

    }
}



.verification-step {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .card-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .card-label {
        div {
            label {
                color: #A0A0A0;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    p {
        font-family: 'Inter';
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: center;
        color: #575757;
        margin: 0 auto;
        width: 210px;

        a {
            color: #4AA879
        }
    }
}

.code-step {
    h5 {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #575757
    }
    p {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    width: 100%;
    max-width: 220px;
    margin: 0 auto;
    color: #575757
    }
    &__input {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 230px;
        margin: auto;
        justify-content: space-between;
    > div {
      width: 100%;
        max-width: 230px;
        margin: auto;
         justify-content: space-between;
     input {
        width: 30px !important;
        height: 30px !important;
        border-radius: 4px !important;

    }
    .incorrect {
        width: 30px !important;
        height: 30px !important;
        border-radius: 4px !important;
        border: 1px solid  red !important
    }
 }

    }

    &__btn {
        display: flex;
        flex-direction: row;
        gap: 10px;

        button {
            width: 48%;
        }
    }

    > span {
        margin-top: -15px;
        font-size: 10px;
    }
}