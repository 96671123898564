.swap-amount-field {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 3px;
  &__title,
  &__balance {
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #818A98;
  }
  &__title {
    font-weight: 700;
    display: flex;
    justify-content: space-between;
  }
  &__max {
    color: #679A8A;
    cursor: pointer;
  }
  &__balance {
    font-weight: 500;
    margin-top: 3px;
  }
  &__amount {
    color: #3B4857;
  }
  &__input {
    width: 330px;
    height: 42px;
    position: relative;
    border: 1px solid #D3DCE6;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 4px 140px 4px 15px;
    margin-top: 3px;
    @media screen and (max-width: 790px) {
      width: 100%;
    }
    input {
      all: unset;
      display: block;
      width: 100%;
      height: 100%;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #3B4857;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type=number] {
        -moz-appearance: textfield;
      }
    }
  }
  &__option {
    &-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2px 0;
    }
    &-balance {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #818A98;
      span {
        display: block;
        text-align: right;
      }
    }
  }
  &__token-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    background: #F9F9FB;
    border-radius: 5px;
    flex: 0 0 auto;
    img {
      display: block;
      width: 12px;
      height: 12px;
    }
  }
  &__select {
    width: 120px;
    height: 32px;
    position: absolute;
    top: 4px;
    right: 4px;
    transition: width .3s ease,
                height .3s ease,
                top .3s ease,
                right .3s ease;
    &:not(.ant-select-customize-input) .ant-select-selector {
      height: 100%;
      background-color: #F3F4F5;
      border-radius: 6px;
      border: none;
      cursor: pointer !important;
    }
    .ant-select-selection-item {
      line-height: 32px !important;
      padding-right: 0;
      .swap-amount-field__option-balance {
        display: none;
      }
      .swap-amount-field__option-currency {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .swap-amount-field__option-wrap {
        padding: 0; 
      }
      .swap-amount-field__token-icon {
        margin-top: 4px;
      }
      .ant-spin {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ant-select-selection-search {
      padding-left: 25px;
      input {
        height: 39px !important;
      }
    } 
    .ant-select-arrow {
      margin-top: -7px;
    }
    &_opened {
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      .ant-select-selection-item {
        line-height: 42px !important;
        padding-right: 0 !important;
        padding-left: 25px !important;
      }
      .ant-select-arrow {
        left: 11px;
        right: unset;
        margin-top: -9px;
      }
    }
  }
  &__dropdown {
    width: 330px;
    @media screen and (max-width: 790px) {
      width: auto;
    }
  }
  &__warning {
    position: absolute;
    top: 100%;
    font-size: 12px;
    color: #e85b48;
    &_min {
      cursor: pointer;
    }
  }
}