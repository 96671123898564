.user-info-modal {
  &__row {
    display: flex;
    gap: 8px;
    margin-top: 20px;
    &:first-of-type {
      margin-top: 30px;
    }
  }
  &__title .text {
    display: flex;
    align-items: center;
  }
  &__edit-button {
    width: 94px;
    height: 36px;
    justify-content: flex-start;
    padding-left: 12px;
    padding-right: 12px;
    margin-right: 0;
    margin-left: auto;
    svg {
      margin-right: 12px;
    }
  }
  &__color-status {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 10px;
    & + .text {
      display: inline-block;
    }
    &_active {
      background: #22B84C;  
    }
    &_inactive {
      background: #E85B48;
    }
  }
}