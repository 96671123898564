.profile-block {
  margin-top: 30px;

  .form__button {
    &.es {
      height: 43px;
    }
  }
}

.profile {
  &__title {
    display: block;
    font-weight: 600;
    font-size: 14px;
    color: #273443;
    &:not(:nth-of-type(1)) {
      margin-top: 20px;
    }
    &_enabled {
      color: #22b84c;
    }
    &_disabled {
      color: #eb5757;
    }
  }
  &__logout {
    width: 160px;
    margin-left: 20px;
    background: transparent;
    color: #191A1D;
    border: 1px solid #191A1D;
    border-radius: 4px;
    &:hover {
      border-color: #303030;
      color: #fff;
    }
  }
  &__subtitle {
    display: block;
    font-size: 12px;
    color: #5f6e80;
    &:not(:nth-of-type(2)) {
      margin-top: 12px;
    }
  }
  &__button {
    width: 100%;
    font-weight: 600;
    border-radius: 4px;
    margin-top: 20px;

    &-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &-col {
    margin-left: auto;
  }
}
