.checkouts-link {
  width: 100%;
  min-height: 80px;
  padding: 12px 20px;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #e0e6ed;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
  &__title {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  &__description {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #818A98;
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #4D7F70;
    box-sizing: border-box;
    border-radius: 4px;
    width: 172px;
    min-height: 34px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #4D7F70;
    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
    &:hover {
      color: #4D7F70;
    }
    svg {
      width: 12px;
      height: 15px;
      stroke: #4D7F70;
      margin-left: 16px;
    }
  }
}