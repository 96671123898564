.not-found {
  width: 310px;
  margin: 100px auto 0;
  &__code {
    font-style: normal;
    font-weight: 700;
    font-size: 150px;
    text-align: center;
    color: #BCD7CD;
  }
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #3B4857;
  }
  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #575757;
  }
  &__button {
    width: 100%;
    height: 36px;
    font-weight: 500;
    margin-top: 40px;
  }
}
