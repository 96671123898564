.release-notes {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  &__item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  &__version {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #3B4857;
  }
  &__date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #8491A5;
  }
  &__new:after {
    content: '';
    position: absolute;
    top: 2px;
    right: 4px;
    width: 6px;
    height: 6px;
    background: #E85B48;
    border: solid 1px #fff;
    border-radius: 50%;
  }
}