.admin-modal {
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    background: rgba(0, 135, 252, 0.06);
    border-bottom: unset;
  }
  .ant-modal-body {
    padding: 20px 20px 30px;
  }
  .ant-modal-footer {
    border-top: unset;
    padding: 10px 20px 30px;
    button + button {
      margin: unset;
    }
  }
}