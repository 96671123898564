.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  font-size: 14px;
  background: #191A1D;
  color: #ffffff;
  border-radius: 4px;
  font-weight: 600;
  line-height: initial;
  border: none;
  transition: background 0.3s ease;
  overflow: hidden;
  cursor: pointer;
  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border: solid 1px #d9d9d9;
    cursor: default;
  }
  &:hover:not(:disabled) {
    background: #303030;
  }
  &:focus,
  &:active {
    outline: none;
  }
  &[type="secondary"] {
    background: transparent;
    border: 1px solid #191A1D;
    color: #191A1D;
    transition: background 0.3s ease, border-color 0.3s ease, color 0.3s ease;
    &:hover:not(:disabled) {
      color: #fff;
      border-color: #303030;
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border: solid 1px #d9d9d9;
      cursor: default;
    }
  }
  &[type="primary"] {
    background: #4D7F70;
    border: 1px solid #4D7F70;
    color: #FFFFFF;
    transition: background 0.3s ease, border-color 0.3s ease, color 0.3s ease;
    &:hover:not(:disabled) {
      color: #fff;
      border-color: #4D7F70;
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border: solid 1px #d9d9d9;
      cursor: default;
    }
  }
  &_spin {
    color: #fff;
    margin-right: 0;
    width: 0;
    opacity: 0;
    transition: margin-right 0.3s ease, width 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
  }
  &_loading {
    opacity: 0.7;
    cursor: default;
    .button_spin {
      opacity: 1;
      margin-right: 7px;
      width: 14px;
    }
    &:disabled {
      background: #4D7F70;
      color: #fff;
    }
  }
}
