.transaction {
  width: 100%;
  border: 1px solid #e0e6ed;
  border-radius: 10px;
  padding: 0 20px;
  margin-top: 12px;
  cursor: pointer;
  @media screen and (max-width: 1091px) {
    padding: 0 10px;
  }
  &:hover {
    background: #F5F8F7;
  }
  > .ant-row {
    height: 72px;
  }
  &__modal {
    &-toggle {
      color: #4D7F70;
      cursor: pointer;
    }
    .ant-modal {
      max-height: 100%;
      display: inline-flex;
      flex-direction: column;
      padding: 20px 0;
    }
    .ant-modal-content {
      border-radius: 10px;
      overflow: scroll;
    }
    .ant-modal-header {
      border: none;
      padding: 16px 20px 10px 20px;
    }
    .ant-modal-body {
      padding: 0 20px 20px 20px;
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-close {
      display: none;
    }
    &-item {
      font-style: normal;
      font-size: 12px;
      line-height: 16px;
      color: #3B4857;
      margin: 0 -20px;
      padding: 10px 20px;
      &:not(:last-of-type) {
        border-bottom: solid 1px #e0e6ed;
      }
    }
    &-label {
      font-weight: 600;
      &:not(:first-of-type) {
        margin-top: 5px;
      }
    }
    &-value {
      font-weight: 400;
    }
    &-valueCopy {
      display: flex;
      p {
        margin-right: 5px;
      }
    }
  }
  &__logo {
    img {
      width: 16px;
      height: 16px;
    }
  }
  &__type {
    font-weight: 600;
    font-size: 14px;
    color: #404040;
    @media screen and (max-width: 1091px) {
      font-size: 12px;
    }
  }
  &__token {
    font-weight: 600;
    font-size: 14px;
    color: #A0A0A0;
    @media screen and (max-width: 1091px) {
      font-size: 12px;
    }
  }
  &__date {
    font-weight: 600;
    font-size: 14px;
    color: #3b4857;
    @media screen and (max-width: 1091px) {
      font-size: 12px;
    }
  }
  &__status {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    @media screen and (max-width: 1091px) {
      font-size: 12px;
    }
    &:before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      margin-right: 4px;
    }
    &_done {
      color: #22b84c;
      &:before {
        background: #22b84c;
      }
    }
    &_pending {
      color: #ff9800;
      &:before {
        background: #ff9800;
      }
    }
    &_failed, &_error {
      color: #eb5757;
      &:before {
        background: #eb5757;
      }
    }
  }
  &__amount {
    text-align: right;
    &_usd {
      font-size: 14px;
      color: #818a98;
      @media screen and (max-width: 1091px) {
        font-size: 12px;
      }
    }
    &_tokens {
      font-weight: 600;
      font-size: 14px;
      color: #404040;
      @media screen and (max-width: 1091px) {
        font-size: 12px;
      }
    }
  }

  &__error-wrapper {
    position: relative;
    padding-left: 12px;
    margin-bottom: 19px;
    &:after {
      content: "";
      display: block;
      width: 3px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(235, 87, 87, 0.4);
      border-radius: 1.5px;
    }
  }

  &__error-title {
    font-weight: 600;
    font-size: 12px;
    color: #000;
  }
  &__error-description {
    font-size: 12px;
    color: #818a98;
  }

  &__details {
    overflow: hidden;
    position: relative;
    font-size: 12px;
    max-height: 0;
    padding-top: 0;
    padding-left: 6px;
    padding-right: 6px;
    transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
    word-break: break-all;
    &_opened {
      max-height: 500px;
      padding-top: 14px;
      padding-bottom: 14px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 15px;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background: #e0e6ed;
    }
    b {
      font-weight: 600;
    }
    &-id {
      margin-top: 24px;
    }
    &-to {
      margin-top: 10px;
      display: flex;
      div {
        &:last-child {
          margin-left: 5px;
        }
      }
    }
    &-from {
      margin-top: 2px;
      display: flex;
      div {
        &:last-child {
          margin-left: 5px;
        }
      }
    }
    &-fee {
      margin-top: 10px;
    }
    &-click-id {
      margin-top: 10px;
    }
    &-hash {
      margin-top: 2px;
      display: flex;
      div {
        &:last-child {
          margin-left: 5px;
        }
      }
      a {
        color: #4D7F70;
      }
    }
  }
}
