.multisend__wrapper {
  display: flex;
}

.multisend {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 16px 36px 30px 36px;
  border: 1px solid #E0E6ED;
  @media screen and (max-width: 768px) {
    padding: 16px 15px 30px 15px;

  }
  &__modal {
    .ant-modal-content {
      border-radius: 10px;
    }
    .ant-modal-body {
      padding: 122px 24px;
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-close {
      display: none;
    }
  }
  &__modal-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__modal-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #818A98;
    margin-top: 30px;
  }
  &__wallet-select {
    width: 406px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    margin-top: 16px;
    .ant-select-selector {
      border: 1px solid #E0E6ED !important;
      border-radius: 10px !important;
      height: 50px !important;
    }
    .ant-select-selection-item, .ant-select-selection-placeholder {
      height: 50px !important;
      line-height: 50px !important;
    }
    .ant-select-selection-item .multisend__wallet-select-option-balance-usd {
      display: none;
    }
    &-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      &-info {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #3B4857;
      }
      &-balance {
        text-align: right;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        &-token {
          color: #3B4857;
        }
        &-usd {
          color: #818A98;
        }
      }
    }
  }
}