.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
  & > p {
    margin: 0;
    // margin-right: 20px;
    @media screen and (max-width: 768px) {
      margin: 0;
      margin-bottom: 20px;
    }
  }

  &__button-create.button {
    display: flex;
    align-items: center;
    min-width: 239px;
    color: #191A1D;
    background: transparent;
    border: solid 1px #191A1D;
    border-radius: 4px;
    transition: all 0.3s ease;
    margin: 0 0 0 auto;
    cursor: pointer;
    @media screen and (max-width: 1023px) {
      margin-left: 0;
      width: 20%;
    }
    @media screen and (max-width: 768px) {
      margin-left: 0;
      width: 100%;
    }
    svg {
      width: 18px;
      height: 18px;
    }
    svg path {
      stroke: #191A1D;
    }
    &:hover {
      background: #303030;
      border-color: #303030;
      color: #fff;
      svg path {
        stroke: #fff;
      }
    }
    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border: solid 1px #d9d9d9;
    cursor: not-allowed;
  }
  &__button-create-icon {
    margin-right: 4px;
  }
}
