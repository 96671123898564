.login-wrapper {
  width: 310px;
  margin: 100px auto 0;
  @media screen and (max-width: 390px) {
    width: 300px;
  }
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #273443;
  }
  &__type {
    &-selector {
      display: flex;
      margin-top: 40px;
    }
    &-item {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #A0A0A0;
      margin-right: 20px;
      cursor: pointer;
      &.active {
        position: relative;
        color: #191A1D;
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 3px;
          position: absolute;
          bottom: -10px;
          left: 0;
          background: #679A8A;
        }
      }
    }
  }
}

.login-form {
  margin-top: 45px;
  &__label {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #273443;
  }
  &__link {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #4D7F70;
  }
  &__item {
    margin-bottom: 20px;
  }
  :global(.login-form__input), &__input {
    width: 100%;
    height: 36px;
    background: #FFFFFF;
    border-radius: 3px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #3B4857;
    border: none;
    &:-webkit-autofill, input:-webkit-autofill,
    &:-webkit-autofill:hover, input:-webkit-autofill:hover,
    &:-webkit-autofill:focus, input:-webkit-autofill:focus,
    &:-webkit-autofill:active, input:-webkit-autofill:active {
      box-shadow: 0 0 0 30px white inset !important;
      -webkit-text-fill-color: #3B4857 !important;
    }
  }
  :global(.login-form__input) {
    padding-left: 40px;
  }
  :global(.login-form__input-phone .flag-dropdown) {
    background: #FFFFFF;
    border: none;
  }
  &__2fa {
    display: flex;
    justify-content: space-between;
    &-input {
      width: 30px;
      height: 30px;
      background: #FFFFFF;
      border: 1px solid #E0E6ED;
      border-radius: 4px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      color: #000000;
      &:focus {
        outline: none;
      }
    }
  }

  &__phrase {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 36px;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    background: #fff;
    padding: 4px 11px;
    @media screen and (max-width: 768px) {
      width: 100%;
      border: 1px solid #d3dce6;
    }
    :global(.react-tagsinput-input),
    :global(.react-tagsinput-tag) {
      margin-top: 2px;
      margin-bottom: 2px;
    }
    :global(.react-tagsinput-input) {
      font-size: 14px;
      width: 100%;
      &::placeholder {
        color: #bfbfbf;
      }
    }
    :global(.react-tagsinput-tag) {
      background: #4D7F70;
      border: none;
      color: #fff;
      :global(.react-tagsinput-remove) {
        color: #fff;
      }
    }
  }

  &__button {
    width: 100%;
    height: 36px;
    font-weight: 500;
    margin-top: 40px;
    &-secondary {
      margin-top: 12px;
    }
  }

  &__divider, &__text, &__auth-links {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #3B4857;
    margin-top: 18px;
    margin-bottom: 18px;
    text-align: center;
  }
  &__auth-links {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
}

.password-changed {
  &__title {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #273443;
    margin-bottom: 17px;
  }
  &__subtitle {
    font-size: 12px;
    color: #3b4857;
    margin-bottom: 20px;
    text-align: center;
  }
}

.email-verified {
  &__title {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #273443;
    margin-bottom: 17px;
  }
  &__subtitle {
    font-size: 12px;
    color: #3b4857;
    margin-bottom: 20px;
    text-align: center;
  }
}

.back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #273443;
  padding-bottom: 7px;
  &__button {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
  }
  svg {
    margin-right: 5px;
    width: 15px !important;
    height: 15px !important;

    path: {
      color: #273443 !important
    }
  }
}