.wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #F5F8F7;
  &__image {
    width: 586px;
    height: 749px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.form-wrapper {
  width: 100%;
  max-width: 616px;
  min-height: 100%;
  background: #DCEBE5;
  position: relative;
  z-index: 1;
  padding: 40px;

  @media screen and (max-width: 1200px) {
    max-width: unset;
  }

  @media screen and (max-width: 390px) {
    padding: 40px 10px;
  }

  &__header {
    display: flex;
    justify-content: space-between;  
    align-items: center;
    :global(.langSelector) {
      margin-top: 0;
    }
    :global(.langSelector__label) {
      display: none;
    }
  }
}
