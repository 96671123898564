.modal-backup {
  &__secret-words {
    height: 88px;
    display: flex;
    border: 1px solid #d3dce6;
    border-radius: 3px;
    margin-top: 20px;
    padding: 10px 0;
  }
  &__placeholder {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }
  &__words-string {
    padding: 0 10px;
  }
  &__secret-tip {
    font-size: 12px;
    text-transform: uppercase;
    color: #8492a5;
  }
  &__form {
    margin-top: 20px;
  }
  &__code {
    margin-top: 20px;
    font-size: 12px;
  }
  &__success-message {
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #22b84c;
    border-radius: 3px;
    padding: 10px;
    font-size: 12px;
    color: #22b84c;
    margin-top: 20px;
    text-align: center;
  }
  &__button-ok {
    width: 100%;
  }
}
