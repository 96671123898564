

.footer {
  border-top: 1px solid #D9D9D9;
  margin: 0px -35px;
  background-color: #ffffff;

  &__container {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
  
  
    @media screen and (min-width: 480px) {
      width: 480px;
    }
  
    @media screen and (min-width: 768px){
      min-width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  
    @media screen and (min-width: 1024px) {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
  
    @media screen and (min-width: 1152px) {
      width: 100%;
      padding-left: 50px;
      padding-right: 50px;
    }
  
    // @include customDesktopMedium {
    //   width: 1265px;
    // }
  
    @media screen and (min-width: 1280px) {
      width: 100%;
    }
  
    @media screen and (min-width: 1440px) {
      width: 100%;
    }
  
    @media screen and (min-width: 1600px) {
      width: 100%;
    }
  }

  padding-bottom: 50px;
  padding-top: 88px;
  
  @media screen and (max-width: 480px) {
    margin: 0px;
    padding-top: 1px;
  }
  @media screen and (max-width: 768px) {
    padding-top: 1px;
  }
  @media screen and (min-width: 768px){
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @media screen and (min-width: 768px) {
    padding-top: 1px;
    padding-bottom: 35px !important;
  }

  &-logo {
    margin-bottom: 60px;

    @media screen and (min-width: 1440px) {
      margin-bottom: 51px;
    }

    &.desktop {
      margin-bottom: 51px;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
       padding-left: 0px;
    }

    @media screen and (min-width: 768px) {
      padding-bottom: 20px;
      margin-bottom: 30px;
      padding-left: 10px;
    }

    @media screen and (min-width: 768px) {
      border-bottom: 1px solid #D9D9D9;
      // width: 60%;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      // margin-right: 25px;
    }

    @media screen and (min-width: 1024px) {
      padding-bottom: 0px;
      border-bottom: 1px solid #D9D9D9;
      // width: 60%;
      width: 100%;

      flex-direction: row;
      justify-content: space-between;
    }

    @media screen and (min-width: 1440px)  {
      padding-bottom: 0px;
      border-bottom: 1px solid #D9D9D9;
      flex-direction: row;
      justify-content: space-between;
    }

    &.desktop {
      flex-direction: row;
      justify-content: space-between;
    }

    //styles for link item

    > .item {
      
      @media screen and (min-width: 768px)  {
        margin-right: 25px;
      }
      // &:nth-of-type(2) {
        
      //   @include desktopLarge {
      //     margin-left: -42px;
      //   }
      // }
      @media screen and (min-width: 1024px)  {
        margin-right: 0px;
      }
      @media screen and (min-width: 1440px)  {
        margin-bottom: 0;
      }

      &.desktop {
        margin-bottom: 0;

        &:nth-of-type(2) {
          margin-left: -20px;
        }
      }

      &.opened {
        margin-bottom: 30px;

        &.desktop {
          margin-bottom: 0;
        }

        @media screen and (min-width: 1440px)  {
          margin-bottom: 0;
        }
      }

      .wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        @media screen and (min-width: 768px)  {
          margin-bottom: 0px;
        }

        @media screen and (min-width: 1440px)  {
          margin-bottom: 14px;
        }

        &.desktop {
          
        }
      }

      .text {
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        color: #222324;

        @media screen and (min-width: 1440px)  {
          font-size: 16px;
        }

        &.desktop {
          font-size: 16px;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      .icon-wrapper {
        cursor: pointer;
        @media screen and (min-width: 1440px)  {
          display: none;
        }
        @media screen and (min-width: 768px) {
          display: none;
        }

        @media screen and (min-width: 1024px) {
          display: none;
        }

        &.desktop {
          display: none;
        }
      }

      .links-list {
        @media screen and (max-width: 767px) {
          height: 0;
        }
        // height: 0;
        overflow-y: hidden;
        transition: height 0.3s ease-in-out;
        list-style: none;
        padding-left: 0;

        &.products.opened {
          height: calc((16px * 6) + (24px * 5));
        }

        &.company.opened {
          height: calc((16px * 1) + (24px * 2));
        }

        &.cases.opened {
          height: calc((16px * 3) + (24px * 3));
        }

        &.supports.opened {
          height: calc((16px * 2) + (24px * 3));
        }

        &.personal.opened {
          height: calc((16px * 1) + (24px * 1));
        }

        &.partner.opened {
          height: calc((16px * 1) + (24px * 2));
        }

        &.business.opened {
          height: calc((16px * 1) + (24px * 1));
        }

        @media screen and (min-width: 1440px)  {
          height: calc((16px * 6) + (24px * 5));
        }
        @media screen and (min-width: 768px){
        overflow-y: visible;


          // height: calc((16px * 7) + (24px * 8));
        }

        @media screen and (min-width: 1024px) {
          height: calc((16px * 6) + (24px * 5));
        }

        &.desktop {
          height: calc((16px * 6) + (24px * 5));
        }

        > li {
          &:not(:last-of-type) {
            margin-bottom: 16px;
          }
        }

        .link {
          a,p {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            cursor: pointer;
            text-decoration: none;
            color: #5F6E80;
          }
        }
      }
    }
  }

    &-info {
    width: 347px;
    font-weight: 300;
    font-size: 13px;
    line-height: 150%;
    color: #5F6E80;
    margin-bottom: 55px;

    // @include mobileMedium {
    //   width: 420px;
    // }

    @media screen and (min-width: 768px){
      margin-bottom: 59px;
      font-size: 14px;
      line-height: 150%;
      color: #000000;
    }

  
    @media screen and (min-width: 768px) {
      // text-align: right;
      margin-left: auto;
      margin-bottom: 0px;
      width: 321px;
      padding-left: 1px;
    }

    @media screen and (min-width: 1024px) {
      // text-align: right;
      margin-left: auto;
      margin-bottom: 63px;
      width: auto;
      padding-left: 1px;
      font-weight: 300;
    }
    @media screen and (min-width: 1440px)  {
      // text-align: right;
      margin-left: auto;
      margin-bottom: 63px;
      
      width: auto;
      padding-left: 1px;
      font-weight: 300;
    }

    &.desktop {
      // text-align: right;
      margin-left: auto;
      margin-bottom: 63px;
      width: 597px;
      width: auto;
      padding-left: 1px;

      @media screen and (min-width: 768px) {
        width: 321px;
      }

      @media screen and (min-width: 1024px) {
        width: 321px;
      }
      // @include desktopCustom {
      //   width: auto;
      // }
    }

    &-desktop {
      display: none;

      @media screen and (min-width: 1440px)  {
        display: block;
      }

      @media screen and (min-width: 768px) {
        display: block;
      }

      @media screen and (min-width: 1024px) {
        display: block;
      }
    }

    &-mobile {
      display: block;

      @media screen and (min-width: 1440px)  {
        display: none;
      }

      @media screen and (min-width: 1024px) {
        display: none;
      }

      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }

    &-links-social {
    display: flex;
    max-width: 300px;
    cursor: pointer;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
      margin-bottom: 20px;
      // margin-left: auto;
    }

    @media screen and (min-width: 1440px)  {
      margin-bottom: 23px;
      // margin-left: auto;
    }
    @media screen and (min-width: 1024px) {
      margin-bottom: 23px;
      // margin-left: auto;
    }

    &.desktop {
      margin-bottom: 23px;
      // margin-left: auto;
    }

    img {
      width: 24px;
      height: 24px;

      @media screen and (min-width: 1440px)  {
        width: 30px;
        height: 30px;
      }

      &.desktop {
        width: 30px;
        height: 30px;
      }
    }

    a {
      margin-bottom: 30px;
      margin-left: 20px;

      &:nth-of-type(n+3){
        margin-bottom: 0;
      }

      @media screen and (min-width: 768px) {
        margin-bottom: 0;
        margin-left: 30px;
      }

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  &-privacy {
    &-wrapper {
      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    &-terms {
      display: flex;
      width: 300px;
      justify-content: space-between;
      max-width: 450px;
      margin-bottom: 20px;

      @media screen and (min-width: 768px) {
        max-width: 240px;
        margin-bottom: 0;
      }

      @media screen and (min-width: 1024px) {
        max-width: 240px;
      }

      &.es {
        width: 320px;
      }

      a {
        all: unset;
        cursor: pointer;
        user-select: none;
        transition: all 0.1s ease-in-out;
        font-weight: 300;
        font-size: 14px;
        line-height: 150%;
        color: #3B4857;
  
        // &:hover {
        //   color: $primaryColor;
        // }
        // &:active {
        //   color: $secondaryColor;
        // }

        @media screen and (min-width: 768px) {
          font-weight: 600;
        }
      }
    }

    &-rights {
      font-weight: 300;
      font-size: 14px;
      line-height: 150%;
      color: #9E9E9E;
  
      @media screen and (min-width: 768px) {
        order: -1;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
      }

      @media screen and (min-width: 1440px) {
        margin-top: -7px;
      }

      &.desktop {
        margin-top: -7px;
      }
    }
  }
}
.user-footer {
  margin-top: 25px;
}
