.withdraw-currencies {
  background: #fff;
  border: 1px solid #E0E6ED;
  border-radius: 10px;
  padding: 27px 20px 31px;
  @media screen and (max-width: 1023px) {
    padding: 27px 15px 31px;
  }
  &__title {
    margin-left: 15px;
    font-weight: 600;
    font-size: 16px;
    color: #000;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1023px) {
      margin-left: 0;
    }
  }
  &__select-all {
    font-size: 14px;
    color: #4D7F70;
    cursor: pointer;
  }
  &__items-wrapper {
    margin-top: 14px;
    @media screen and (max-width: 1023px) {
      overflow: scroll;
      height: 292px;
    }
  }
  &__loader {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}