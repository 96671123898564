.release-notes-modal {
  max-width: 600px;
  min-width: 320px;
  width: calc(100% - 40px) !important;
  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
  }
  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #4D7F70;
  }
  &__subtitle {
    font-size: 12px;
    line-height: 16px;
    color: #818A98;
  }
  &__list {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000; 
    padding-left: 10px;
  }
  &__list-item {
    font-size: 14px;
    line-height: 19px;
    color: #3B4857;
    font-weight: normal;
    margin-top: 16px;
    list-style: none;
    position: relative;
    white-space: pre-line;
    &:not(:first-of-type) {
      margin-top: 20px;
    }
    &:before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      position: absolute;
      top: 7px;
      left: -14px;
      background: #EB596B;
      border-radius: 50%;
    }
  }
}