.merchant-data-form {
  margin-top: 0;
  .form__title {
    margin-top: 40px;
  }
  &__api-key {
    word-break: break-all;
  }

  .form__button {

    &.es {
      height: 45px;
    }
  }
}
