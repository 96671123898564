.admin-settings-form {
  &__selected-wallet-label {
    flex: 100%;
    font-weight: 600;
    font-size: 12px;
    color: #273443;
  }
  &__selected-wallet {
    height: 36px;
    display: flex;
    align-items: center;
    border: 1px solid #d3dce6;
    padding: 0 10px;
    border-radius: 3px;
    flex: auto;
    margin-right: 20px;
    &:hover {
      transition: all 0.3s ease;
    }
    img {
      width: 20px;
      height: 20px;
    }
    &-info {
      margin-left: 10px;
    }
  }
  &__exchange-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    .form__item {
      flex: auto;
      margin-right: 20px;
    }
    &-icon {
      margin-bottom: 8px;

       &-animated {
        animation: spin 2s linear infinite;
      }
    }
    label {
      width: 100%;
    }
    &_label {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    &_min-value {
      font-size: 10px;
      color: #9c9fa1;
    }

    &_warning {
      padding-bottom: 22px;
    }
  }
  &__input-number {
    width: 100%;
  }
  &__input-usd {
    width: 100%;
    padding: 0 11px;
    outline: none;
    &:hover {
      border-color: #40a9ff;
    }
    &:focus {
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }
  &__balance {
    font-size: 20px;
  }
  &__radio-group {
    display: flex;
    width: 100%;
    margin-top: 20px;
  }
  &__radio-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    position: relative;
    border-radius: 40px !important;
    border-left-width: 1px;
    margin-right: 20px;
    &:before {
      display: none !important;
    }
    &-content {
      display: flex;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    &-img {
      width: 26px;
      height: 26px;
      margin-left: 12px;
    }
    &-text {
      margin-left: 10px;
    }
    &-tick {
      display: none;
      position: absolute;
      right: 15px;
    }
    &-btc {
      width: 135px;
    }
    &-eth {
      width: 151px;
    }
    &-usdt {
      width: 182px;
    }
  }
  .ant-radio-button-wrapper-checked {
    .settings-form__radio-button-tick {
      display: block;
    }
  }
  &__system-fee {
    font-size: 14px;
    color: #9c9fa1;
    margin-top: 20px;
  }
}

.withdraw__buttons {
  margin-top: 20px;
}

.admin-withdraw-amount {
  &__label {
    position: relative;
    width: auto;
    margin-right: 41px;
    font-weight: 600;
    font-size: 12px;
    color: #273443;
  }
  &__decimals-limit {
    position: absolute;
    top: 100%;
    font-size: 12px;
    color: #e85b48;
  }
  &__input-wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
  }
  &__input {
    margin-right: 20px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
}

.max {
  &-container {
    position: absolute;
    top: 6px;
    right: 50px;
  }

  &-text {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #4D7F70;
    cursor: pointer;
  }

  &-warning {
    font-size: 10px;
    line-height: 14px;
    color: #E85B48;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


