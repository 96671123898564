.qr-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__address,
  &__amount {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid #f0efeb;
    border-radius: 10px;
    padding: 13px 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #8491a5;
    cursor: pointer;
    &:first-of-type {
      margin-top: 24px;
    }
    &:not(:first-of-type) {
      margin-top: 12px;
    }
    &:last-of-type {
      margin-bottom: 30px;
    }
    &_error {
      border: 1px solid #ff4d4f;
    }
  }
  &__amount-input {
    text-align: right;
    border: none;
    outline: none;
    margin-right: 5px;
  }
  &__tolerance {
    width: 100%;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #818A98;
    margin-top: 12px;
  }
  &__copy-block {
    color: #000000;
    display: flex;
    align-items: center;
    & > svg {
      margin-left: 24px;
      fill: #4D7F70;
    }
  }
  &__min-payment {
    width: 100%;
    min-height: 44px;
    background: #F5F5FB;
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #4D7F70;
    margin-top: 20px;
    margin-bottom: 36px;
    text-align: left;
    svg {
      margin-right: 10px;
    }
  }
}
