.swap-wallet-item {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #3B4857;
  border-radius: 8px;
  transition: background .3s ease;
  &:not(:first-of-type) {
    margin-top: 10px;
  }
  cursor: pointer;
  &_active, &:hover {
    background: #FBFAFD;
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__right {
    text-align: right;
  }
  &__fiat {
    color: #818A98;
    @media screen and (max-width: 590px) {
      font-size: 12px;
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #F9F9FB;
    border-radius: 8px;
    margin-right: 10px;
    img {
      width: 20px;
      height: 20px;
    }
    @media screen and (max-width: 590px) {
      display: none; 
    }
  }
  &__address {
    color: #679A8A;
    cursor: pointer;
    @media screen and (max-width: 590px) {
      width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px; 
    }
  }
}