.transactions {
  position: relative;
  background: #fff;
  border: 1px solid #e0e6ed;
  border-radius: 10px;
  padding: 20px 20px 40px 20px;
  @media screen and (max-width: 1023px) {
    padding: 20px 15px 40px 15px;
  }
  &__legend {
    font-weight: 600;
    font-size: 14px;
    color: #818a98;
    padding: 0 20px;
    margin-top: 40px;
    &-amount {
      text-align: right;
    }
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
  &__load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 144px;
    height: 34px;
    background: #191A1D;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
    color: #ffffff;
    margin: 30px auto 0;
    cursor: pointer;
  }
  &-list {
    overflow: auto;
    &__empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      &-title {
        font-size: 16px;
        color: #818a98;
      }
      &-image {
        margin-top: 45px;
      }
    }
  }
  &__loader {
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
