.hamburger-menu {
    width: 24px;
    height: 14px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    & span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #5f6e80;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
        &:before {
            content: "";
            position: absolute;
            left: 3px;
            top: 0;
            width: 4px;
            height: 100%;
            background: #fff;
        }
    }
    & span:nth-child(1) {
        top: 0px;
    }
    & span:nth-child(2),
    & span:nth-child(3) {
        top: 7px;
    }
    & span:nth-child(4) {
        top: 14px;
    }
    &__opened {
        & span {
            &:before {
                background: transparent;
            }
        }
        & span:nth-child(1) {
            top: 6px;
            width: 0%;
            left: 50%;
        }

        & span:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        & span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        & span:nth-child(4) {
            top: 6px;
            width: 0%;
            left: 50%;
        }
    }
}
