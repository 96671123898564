.modal-delete-merc {
  .ant-modal-header {
    display: none;
  }
  .ant-modal-body {
    padding-top: 40px;
    padding-bottom: 40px;
    @media screen and (max-width: 768px) {
      padding: 15px;
    }
  }
  .ant-modal-close {
    display: none;
  }
  &__buttons-wrapper {
    margin-top: 0;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      & * {
        max-width: 100%;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        & > button {
          width: 100%;
        }
      }
    }
  }
}
