.modal__private-key {
  .modal {
    &__text {
      margin-bottom: 20px;
      cursor: pointer;
    }
    &__warning {
      text-align: center;
    }
    &__button-create {
      // width: 100%;
    }
  }
}
