@import "../src/highlight.scss";

body {
  background: #f1f3f6;
}

#root {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  overflow: hidden;
  @media screen and (max-width: 1023px) {
    height: 100%;
    max-height: unset;
    overflow: auto;
  }
}

a {
  color: #4D7F70;
  &:hover {
    color: #4D7F70;
    opacity: 0.8;
  }
}

.text-normal {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.flex-auto {
  flex: auto;
}
.block-for-component {
  flex: 1 0 auto;
}

.component {
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 2px 4px 16px rgba(115, 129, 143, 0.16);
  &_form-padding {
    padding-bottom: 40px;
  }
  &__title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-weight: 600;
    font-size: 16px;
  }
}

.strong {
  font-weight: 600;
}

.ant-layout {
  // min-width: 1366px;
  height: 100vh;
  overflow: hidden;
  &-header {
    padding: 0;
    box-shadow: 2px 4px 16px rgba(115, 129, 143, 0.16);
  }
  &-header,
  &-sider {
    background: #ffffff;
  }
  &-content {
    padding: 20px 32px 63px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    > div {
      padding-bottom: 63px;
    }
  }
}

.form {
  margin-top: 30px;
  &__item {
    margin-top: 12px;
    margin-bottom: 0;
  }
  &__input {
    height: 36px;
    border: 1px solid #d3dce6;
    border-radius: 3px;
    &:hover {
      border: 1px solid #d3dce6;
    }
    &:focus {
      box-shadow: none;
    }
    &.ant-input-affix-wrapper:focus, &.ant-input-affix-wrapper-focused {
      border-color: #d9d9d9;
      box-shadow: none;
    }
  }
  &__input.form__input-phone {
    border: 1px solid #d3dce6;
    padding: 4px 11px 4px 0px;
    background: #fff;
    input {
      border-radius: 2px;
      border: none;
    }
    .flag-dropdown {
      background-color: #fff;
      border: none;
    }
    .form-control {
      width: 100%;
      height: 100%;
    }
  }
  &__prefix-input {
    .ant-input-group-addon {
      background-color: #fff;
    }
    .ant-select-selector {
      margin: 0 !important;
      padding: 2px 0 !important;
      width: 100% !important;
      min-width: unset !important;
    }
    .ant-select {
      max-width: 100px;
    }
    .ant-select-selection-item {
      padding-right: 30px !important;
      padding-left: 10px !important;
    }
    .ant-input {
      height: 36px;
    }
  }
  &__textarea {
    border-radius: 3px;
  }
  &__title {
    display: block;
    font-weight: 600;
    font-size: 14px;
    color: #273443;
    &:not(:nth-of-type(1)) {
      margin-top: 20px;
    }
  }
  &__subtitle {
    display: block;
    font-size: 12px;
    color: #5f6e80;
    &:not(:nth-of-type(2)) {
      margin-top: 12px;
    }
  }
  &__select .ant-select-selector {
    min-height: 36px;
    max-height: 36px;
    border-radius: 3px !important;
    align-items: center;
  }
  &__button {
    width: 100%;
    font-weight: 600;
    border-radius: 4px;
    margin-top: 20px;
  }
  .ant-form-item-label {
    padding-bottom: 0;
    label {
      font-weight: 600;
      font-size: 12px;
      color: #273443;
    }
  }
}

.border-line {
  height: 1px;
  margin: 26px -20px 0;
  background: #b0bbcb;
  opacity: 0.3;
}

.two-factor-qr {
  display: block;
  margin: 20px auto;
}

.react-tagsinput-remove:before {
  content: " ×" !important;
}

.recharts-surface {
  overflow: visible !important;
}

.recharts-wrapper {
  padding-left: 20px;
}

.custom-tooltip {
  width: 150px;
  height: 150px;
  background-color: white;
}

.recharts-label {
  font-weight: 600;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
