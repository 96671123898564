.switcher-wrapper {
  margin-top: 25px;
  &__description {
    margin-top: 9px;
  }
  &__switcher {
    width: 58px;
    margin-top: 20px;
    background: #D3DCE6;
    &.ant-switch-checked {
      background: #4D7F70;
    }
  }
  .ant-row.ant-form-item {
    margin-bottom: 0;
  }
}