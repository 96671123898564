.modal__wallet-create {
  .ant-modal-header {
    text-transform: capitalize;
  }
  &_type {
    .ant-modal-body {
      padding: 40px 108px;
      @media screen and (max-width: 768px) {
        padding: 40px 15px;
      }
    }
    .modal__text {
      margin-bottom: 0;
      font-size: 12px;
    }
    .modal__buttons-wrapper {
      margin-top: 20px;
    }
  }
  &_new,
  &_existing {
    width: 604px !important;
    .ant-modal-body {
      padding: 20px 48px 40px;
      @media screen and (max-width: 768px) {
        padding: 20px 15px 40px;
      }
    }
    .modal-form__label {
      width: 100%;
      max-width: 350px;
      margin: 20px auto 0;
    }
    .modal__set-default {
      width: 100%;
      max-width: 350px;
      margin: 20px auto 0;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background: #4D7F70;
      border-color: #4D7F70;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-checked::after {
      border-color: #4D7F70;
    }
  }
  &_new {
    .modal__buttons-wrapper {
      margin-top: 20px;
    }
  }

  .modal {
    &__buttons-wrapper {
      justify-content: space-between;
    }
    &__radio-group {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
    }
    &__radio-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      position: relative;
      border-radius: 40px !important;
      border-left-width: 1px;
      &:before {
        display: none !important;
      }
      &-content {
        display: flex;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      &-img {
        width: 26px;
        height: 26px;
        margin-left: 12px;
      }
      &-text {
        margin-left: 10px;
      }
      &-tick {
        display: none;
        position: absolute;
        right: 15px;
      }
      &-btc {
        width: 135px;
      }
      &-eth {
        width: 151px;
      }
      &-usdt {
        width: 182px;
      }
      &.ant-radio-button-wrapper-checked {
        .modal__radio-button-tick {
          display: block;
        }
      }
    }
  }
}
