.swap-network-warn {
  max-width: 400px;
  width: 100% !important;
  .ant-modal-content {
    border-radius: 10px;
    margin: 0 10px;
  }
  .ant-modal-header, .ant-modal-close {
    display: none;
  }
  .ant-modal-body {
    padding: 24px 20px 30px;
  }
  &__message {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #818A98;
    margin-top: 20px;
    white-space: pre-line
  }
  &__checkbox {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: #818A98;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    button {
      width: 150px;
    }
  }
}