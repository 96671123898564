.swap-history-filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 0 10px;
  .ant-form-item {
    margin-bottom: 0;
  }
  &__token-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    background: #F9F9FB;
    border-radius: 5px;
    flex: 0 0 auto;
    img {
      display: block;
      width: 12px;
      height: 12px;
    }
  }
  &__search-wrap {
    position: relative;
    padding-bottom: 20px;
    @media screen and (max-width: 790px) {
      width: 100%;
    }
    @media screen and (max-width: 590px) {
      width: auto;
    }
  }
  &__search-found {
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #9B9FB8;
    bottom: 0;
    span {
      color: #679A8A;
    }
  }
  &__search-label {
    @media screen and (max-width: 790px) {
      width: 100%;
    }
    @media screen and (max-width: 590px) {
      width: auto;
    }
  }
  &__search {
    width: 158px;
    height: 36px;
    border: 1px solid #D3DCE6;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9B9FB8;
    @media screen and (max-width: 790px) {
      width: 100%;
    }
    .ant-input-prefix {
      cursor: pointer;
    }
  }
  &__label {
    @media screen and (max-width: 590px) {
      margin-bottom: 0;
      .ant-col.ant-form-item-label {
        padding: 0;
      }
    }
    label {
      height: 36px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #818A98;
      @media screen and (max-width: 590px) {
        width: 80px;
      } 
    }
  } 
  &__currencies-pair {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    @media screen and (max-width: 590px) {
      justify-content: space-between;
      margin-bottom: 30px;
      .ant-row.ant-form-item {
        margin-bottom: 0;
      }
    }
    &-divider {
      margin: 0 10px;
    }
  }
  &__select {
    .ant-select-selector {
      width: 110px !important;
      height: 36px !important;
      border: 1px solid #D3DCE6 !important;
      border-radius: 6px !important;
      cursor: pointer !important;
    }
    .ant-select-selection-item {
      margin-top: 3px;
    }
    .ant-select-arrow {
      display: flex;
      align-items: center;
    }
    .ant-select-selection-search {
      left: 25px !important;
      right: 11px !important;
    }
    &-sort {
      .ant-select-selector {
        width: 140px !important;
        @media screen and (max-width: 590px) {
          width: 100% !important;
        }
      }
    }
    &_opened {
      .ant-select-selector {
        cursor: text !important;
      }
      .ant-select-selection-item {
        padding-right: 0 !important;
        padding-left: 18px !important;
      }
      .ant-select-arrow {
        left: 11px;
        right: unset;
        margin-top: -7px;
      }
    }
  }
  &__filters-switch {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #679A8A;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-bottom: 20px;
    svg {
      margin-right: 5px;
    }
  }
  &__modal {
    top: 100%;
    transform: translateY(-100%);
    padding-bottom: 0;
    max-width: unset;
    width: 100% !important;
    margin-top: 0;
    .ant-modal-content {
      border-radius: 10px 10px 0px 0px;
      overflow: hidden;
    }
    .ant-modal-header {
      border-bottom: none;
    }
    .ant-modal-body {
      max-width: 400px;
      margin: 0 auto;
      padding: 20px 15px;
    }
  }
  &__modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    button {
      min-width: 155px;
      height: 42px;
    }
  }
}