.total-balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background: #fff;
  border: 1px solid #E0E6ED;
  border-radius: 10px;
  padding: 0 20px 0 27px;
  margin-bottom: 20px;
  &__info {
    display: flex;
    flex-direction: column;
    max-width: 90%;
    flex: auto;
    margin-top: 1px;
    padding-right: 10px;

    &-wrapper {
      display: flex;
      width: 120px;
      justify-content: space-between;

      > button {
        all: unset;
        cursor: pointer;
      }
    }
  }
  &__title {
    color: #818A98;
    font-size: 14px;
  }
  &__balance {
    color: #3B4857;
    font-weight: 600;
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: normal;
    margin-top: 5px;
    white-space: nowrap;
  }
  &__loader {
    display: flex;
    margin-top: 20px;

    >p {
      margin-right: 30px;
    }
  }
}