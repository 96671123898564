.swap-details-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #F5F5FB;
  padding: 80px 15px 17px;
  overflow: scroll;
  .swap-back {
    margin-bottom: 20px;
  }
  &__card {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px 10px;
  }
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #818A98;
    text-align: center;
  }
  &__id {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #3B4857;
    margin-top: 30px;
  }
  &__date {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #818A98;
    margin-top: 5px;
  }
  &__status {
    text-align: center;
    margin-top: 15px;
    span {
      border-radius: 30px;
      padding: 4px 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
    }
    &_new span {
      background: #F3F4F5;
    }
    &_done span {
      color: #23B852;
      background: #E9F8EE;
    }
    &_pending span {
      color: #F99400;
      background: #FEF4E5;
    }
    &_failed span {
      color: #E85B48;
      background: #FFF2F2;
    }
  }
  &__amount {
    margin-top: 30px;
    &-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: #818A98;
    }
    &-to,
    &-from {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #3B4857;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      text-align: center;
      svg {
        width: 24px;
        height: 24px;
        margin-right: 5px;
      }
    }
    &-to {
      margin-top: 10px;
    }
    &-name {
      color: #818A98;
      margin-left: 5px;
    }
  }
  &__partner,
  &__type {
    display: flex;
    justify-content: center;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #3B4857;
    &-value {
      display: flex;
      align-items: center;
      font-weight: 400;
      margin-left: 20px;
      img, svg {
        max-width: 16px;
        max-height: 16px;
        margin-right: 5px;
      }
    }
  }
  &__partner {
    margin-top: 40px;
  }
  &__type {
    margin-top: 10px;
  }
}