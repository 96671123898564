.swap-offers-filters {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  @media screen and (max-width: 590px) {
    justify-content: flex-end;
    width: auto;
    margin-top: 0;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  &__label {
    @media screen and (max-width: 590px) {
      width: 100%;
    }
    &:first-of-type {
      margin-right: 30px;
    }
    label {
      height: 36px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #818A98;
      @media screen and (max-width: 590px) {
        width: 80px;
        margin-right: 24px;
      }
    }
  } 
  &__select {
    .ant-select-selector {
      width: 110px !important;
      height: 36px !important;
      border: 1px solid #D3DCE6 !important;
      border-radius: 6px !important;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
    .ant-select-arrow {
      display: flex;
      align-items: center;
    }
    .ant-select-selection-search {
      left: 25px !important;
      right: 11px !important;
    }
    &-sort {
      .ant-select-selector {
        width: 150px !important;
        @media screen and (max-width: 590px) {
          width: 100% !important;
        }
      }
    }
    &_opened {
      .ant-select-selection-item {
        padding-right: 0 !important;
        padding-left: 18px !important;
      }
      .ant-select-arrow {
        left: 11px;
        right: unset;
        margin-top: -7px;
      }
    }
  }
  &__filters-switch {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #679A8A;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: 5px;
    }
  }
  &__modal {
    top: 100%;
    transform: translateY(-100%);
    padding-bottom: 0;
    max-width: unset;
    width: 100% !important;
    margin-top: 0;
    .ant-modal-content {
      border-radius: 10px 10px 0px 0px;
      overflow: hidden;
    }
    .ant-modal-header {
      border-bottom: none;
    }
    .ant-modal-body {
      max-width: 400px;
      margin: 0 auto;
      padding: 20px 15px;
    }
  }
  &__modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    button {
      min-width: 155px;
      height: 42px;
    }
  }
}