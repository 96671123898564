.swap-history-item {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #3B4857;
  border-radius: 8px;
  transition: background .5s ease;
  cursor: pointer;
  &:not(:first-of-type) {
    margin-top: 20px;
  }
  &_opened {
    background: #FBFAFD;
  }
  &__main-data {
    display: flex;
    align-items: center;
    padding: 10px;
    @media screen and (max-width: 790px) {
      justify-content: space-between;
      padding: 20px 10px;
    }
  }
  &__id {
    width: 144px;
    color: #404040;
  }
  &__date {
    display: flex;
    flex-direction: column;
    width: 117px;
    @media screen and (max-width: 790px) {
      display: none;
    }
  }
  &__time {
    color: #818A98;
  }
  &__currency {
    display: flex;
    width: 367px;
  }
  &__currency-item {
    display: flex;
    align-items: center;
    &:first-of-type {
      margin-right: 10px;
    }
    &:last-of-type {
      margin-left: 10px;
    }
  }
  &__currency-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    background: #F9F9FB;
    border-radius: 5px;
    img {
      display: block;
      width: 12px;
      height: 12px;
    }
  }
  &__status {
    span {
      display: block;
      text-align: center;
      border-radius: 30px;
      padding: 4px 16px;
      font-weight: 500;
    }
    &_new span {
      background: #F3F4F5;
    }
    &_done span {
        color: #118C4F;
        background: #ECF6F1;
      }
    &_pending span {
        color: #EAB308;
        background: #FEFCE8;
      }
    &_failed span {
        color: #D30F45;
        background: #FFE8ED;
      }
  }

}

.swap-history-details {
  max-height: 0;
  transition: max-height .5s ease,
              padding .5s ease;
  overflow: hidden;
  padding: 0 10px;
  cursor: auto;
  &_opened {
    padding: 20px 10px;
    max-height: 1000px;
    background-color: #F5F8F7;
  }
  &__card {
    background: #F3F4F5;
    border-radius: 8px;
    border: 1px solid #DCEBE5;
    padding: 20px;
    margin-top: 22px;
    &:last-of-type {
      margin-top: 10px;
    }
    &-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #818A98;
      margin-bottom: 20px;
    }
  }
  &__partner,
  &__type {
    display: flex;
    &-title {
      flex: 1;
    }
    &-value {
      display: flex;
      align-items: center;
      font-weight: 400;
      flex: 8;
      img, svg {
        max-width: 16px;
        max-height: 16px;
        margin-right: 5px;
      }
    }
  }
  &__type {
    margin-top: 10px;
  }
  &__wallet,
  &__paymentId,
  &__amount,
  &__hash {
    display: flex;
    @media screen and (max-width: 590px) {
      flex-direction: column;
    }
    &:not(:first-of-type) {
      margin-top: 10px;
      @media screen and (max-width: 590px) {
        margin-top: 20px;
      }
    }
    &-title {
      flex: 1;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #404040;
    }
    &-value {
      flex: 6;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }
  &__wallet-value  {
    color: #679A8A;
    word-break: break-all;
  }
  &__paymentId-value {
    color: #A0A0A0;
    word-break: break-all;
  }
  &__amount-value {
    color: #A0A0A0;
  }
  &__hash-value {
    color: #679A8A;
    font-weight: 500;
    word-break: break-all;
    cursor: pointer;
  }
}