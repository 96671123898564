.swap-exchange-wrap {
  @media screen and (min-width: 591px) {
    z-index: 1;
    position: sticky;
    top: 0;
    outline: solid 20px #f1f3f6;
    &::before, &:after {
      content: '';
      position: absolute;
      background: #f1f3f6;
      width: 100%;
      height: 20px;
      left: 0;
    }
    &::before {
      bottom: 100%;
    } 
    &:after {
      top: 100%;
    }
  }
}

.swap-exchange {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  @media screen and (max-width: 590px) {
    flex-direction: column;
    align-items: flex-end;
  }
  &__reverse {
    margin-top: -11px;
    cursor: pointer;
    &_fetching {
      cursor: default;
    }
    @media screen and (max-width: 790px) {
      margin: 3px 10px 0;
    }
    @media screen and (max-width: 590px) {
      margin: 10px 0 10px;
    }
  }
}