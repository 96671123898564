.admin-header {
  padding: 0 32px 0 0;
  position: relative;
  z-index: 1;
  box-shadow: 2px 4px 16px rgba(115, 129, 143, 0.16);
  &__title {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 0;
  }
  &__toggle-sider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 90px;
    text-align: center;
    user-select: none;
    color: #3b4857;
    cursor: pointer;
    svg {
      width: 22px;
      height: 22px;
    }
  }
  &__controls-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    line-height: normal;
  }
  &__merchant-selector-wrapper {
    justify-content: center;
  }
  &__merc-button {
    min-width: 160px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4D7F70;
    border: solid 1px #4D7F70;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    transition: background 0.3s ease;
    padding: 0 15px;
    line-height: 34px;
    cursor: pointer;
    &:hover {
      img {
        transform: rotate(180deg);
      }
    }
    img {
      margin-top: 2px;
      margin-left: 10px;
    }
  }
  &__user {
    height: 64px;
    display: flex;
    align-items: center;
  }
  &__user-name {
    display: flex;
    font-weight: 600;
    font-size: 14px;
    margin-right: 30px;
    color: #273443;
    cursor: pointer;
    > img {
      margin-right: 10px;
    }
  }
  &__dropdown {
    width: 137px;
    &-item {
      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
      a {
        color: #3b4857;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  &__network-filter-wrap {
    display: flex;
    justify-content: flex-end;
  }
}
