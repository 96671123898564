.dashboard-topfive {
    width: 100%;
    &__title {
        margin: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #273443;
        margin-bottom: 30px;
    }
    &__list {
        display: flex;
        gap: 38px;
        &-item {
            flex: 1;
            background: #ffffff;
            max-width: 200px;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap: 5px;
            padding: 17px 20px;
            &-img {
                width: 100%;
                max-width: 30px;
                height: 100%;
                max-height: 30px;
            }
            &-name {
                margin: 0;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                color: #818a98;
                text-align: center;
            }
            &-volume {
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 27px;
                color: #3b4857;
            }
        }
    }
}
