.langSelector, .langSelectorTokenCheckout {
  position: relative;
  height: 100%;
  display: inline-block;
  align-items: center;
  line-height: normal;
  margin-top: 30px;
  &__label {
    display: block;
    font-weight: 600;
    font-size: 14px;
    color: #273443;
    margin-bottom: 10px;
  }
  &__selectedFlag {
    margin-right: 14px;
  }
  &:hover {
    .langSelector__dropdown {
      display: block;
    }
    .langSelector__arrow {
      transform: rotateZ(180deg);
      cursor: pointer;
    }
  }
  &__dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 92%;
    transform: translateX(-50%);
    background: #fff;
    padding: 10px;
    box-shadow: 2px 4px 12px rgba(59, 72, 87, 0.18);
    z-index: 1;
    @media screen and (max-width: 1359px) {
      left: -2px;
      transform: unset;
      top: 130%;
    }
  }
  &__option {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    &:not(:first-of-type) {
      margin-top: 5px;
    }
    &_selected,
    &:hover {
      background: rgba(41, 136, 239, 0.1);
      border-radius: 4px;
    }
  }
  &__flag {
    margin-right: 10px;
  }
  &__name {
    font-size: 16px;
    color: #273443;
  }
}

.langSelectorTokenCheckout {
  margin-top: 0;
  position: absolute;
  top: 43px;
  right: 45px;
  height: 300px;

  .langSelector__label {
    display: none;
  }

  .langSelector__dropdown {
    top: 10%;

    @media screen and (min-width: 768px) {
      left: -12%;
    }

    @media screen and (max-width: 1359px) {
      left: -87%;
    }
  }
}
