.multisend-confirm {
  &__modal {
    .ant-modal-content {
      border-radius: 10px;
    }
    .ant-modal-body {
      padding: 90px 24px;
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-close {
      display: none;
    }
    &-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-text,
    &-subtext {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #818A98;
      margin-top: 16px;
      text-align: center;
      &-success {
        font-size: 24px;
        color: #4DD162;
      }
      &-subtext {
        margin-top: 10px;
      }
    }
    &-buttons {
      width: 100%;
      margin-top: 20px;
      .button {
        margin: 0 auto;
        min-width: 200px;
        height: auto;
        min-height: 40px;
        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }
    }
  }
  &__twoFa {
    display: flex;
    flex-direction: column;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #273443;
    }
    span::before {
      content: '*';
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      line-height: 1
    }
    input {
      border-radius: 4px;
      margin-bottom: 15px;
    }
    &-button {
      margin-top: 10px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 188px;
    min-height: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    &:first-of-type {
      margin-right: 20px;
      @media screen and (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
  &__wrapper-2fa {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}