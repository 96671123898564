.password-strength {
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  &__title img {
    margin-left: 8px;
  }
  &__popover-text {
    margin-bottom: 0;
  }
  &__popover {
    &-icon_active {
      display: none;
    }
    &.ant-popover-open {
      .password-strength__popover-icon {
        display: none;
      }
      .password-strength__popover-icon_active {
        display: inline;
      }
    }
  }
  &__scale {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
    & > div {
      width: calc(20% - 11px);
      height: 4px;
      border-radius: 4px;
      background: #fff;
      @media screen and (max-width: 768px) {
        background: #D3DCE6;
      }
    }
    &[level="1"] > div:nth-child(-n + 1),
    &[level="2"] > div:nth-child(-n + 2),
    &[level="3"] > div:nth-child(-n + 3),
    &[level="4"] > div:nth-child(-n + 4),
    &[level="5"] > div:nth-child(-n + 5) {
      background: #4D7F70;
    }
  }
}
