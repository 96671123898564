
.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  @media screen and (max-width: 756px) {
      padding: 35px 15px;
      overflow: scroll;
      align-items: flex-start;
  }

  &__content {
      position: relative;
      margin: auto;
      padding: 60px 124px 47px;
      width: 100%;
      max-width: 695px;
      background: #f7fafe;
      border-radius: 4px;
      @media screen and (max-width: 756px) {
          padding: 30px 15px 20px;
      }
  }

  &__close {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
          transform: scale(1.2);
      }
      @media screen and (max-width: 756px) {
          top: 5px;
          right: 5px;
      }
  }

  &__shadow {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: grey;
      opacity: 0.5;
      z-index: -1;
  }
}

.self-hosted-content {
  background: #f3f1fd;
}

.request-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin-bottom: 0px;
    }
    &__success-message {
        text-align: center;
    }

    &__title {
        text-align: center;
        margin-bottom: 40px;
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
    }

    &__captcha-container {
        margin-bottom: 20px;

        &.self-hosted-new-form {
            margin: 20px auto 20px;
        }
    }

    &__input,
    &__text-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        margin-bottom: 30px;

        & > input {
            width: 100%;
            padding: 13px 16px;
            border: 1px solid transparent;
            border-radius: 4px;
            background-color: #ffffff;
            outline: none;
            color: #222324;
            box-shadow: 2px 4px 16px rgba(115, 129, 143, 0.16);
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            &::placeholder {
                color: #4D7F70;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: left;
            }
            &::-moz-placeholder {
                color: #4D7F70;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: left;
            }
            &::-webkit-input-placeholder {
                color: #4D7F70;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: left;
            }
            &::-ms-input-placeholder {
                color: #4D7F70;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: left;
            }
            &:focus::-webkit-input-placeholder {
                color: transparent !important;
            }
            &:focus::-moz-placeholder {
                color: transparent !important;
            }
            &:focus:-moz-placeholder {
                color: transparent !important;
            }
            &:hover {
                &::placeholder {
                    font-weight: 500 !important;
                }
                &::-moz-placeholder {
                    font-weight: 500 !important;
                }
                &::-webkit-input-placeholder {
                    font-weight: 500 !important;
                }
                &::-ms-input-placeholder {
                    font-weight: 500 !important;
                }
            }
        }
    }
    &__text-area {
        & > label {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        & > textarea {
            resize: none;
            width: 100% !important;
            height: 151px;
            padding: 13px 16px;
            border: 1px solid transparent;
            border-radius: 4px;
            background-color: #ffffff;
            outline: none;
            color: #222324;
            box-shadow: 2px 4px 16px rgb(115 129 143 / 16%);
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            &::placeholder {
                color: #4D7F70;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: left;
            }
            &::-moz-placeholder {
                color: #4D7F70;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: left;
            }
            &::-webkit-input-placeholder {
                color: #4D7F70;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: left;
            }
            &::-ms-input-placeholder {
                color: #4D7F70;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: left;
            }
            &:focus::-webkit-input-placeholder {
                color: transparent !important;
            }
            &:focus::-moz-placeholder {
                color: transparent !important;
            }
            &:focus:-moz-placeholder {
                color: transparent !important;
            }
            &:hover {
                &::placeholder {
                    font-weight: 500 !important;
                }
                &::-moz-placeholder {
                    font-weight: 500 !important;
                }
                &::-webkit-input-placeholder {
                    font-weight: 500 !important;
                }
                &::-ms-input-placeholder {
                    font-weight: 500 !important;
                }
            }
        }
        &-chars-limit {
            font-size: 12px;
            color: #222324;
        }
    }
    &__request-error {
        margin-top: -20px;
        margin-bottom: 20px;
        padding: 16px;
        width: 100%;
        background-color: #c78b8c46;
        color: #fc4b4e;
        border-radius: 4px;
        cursor: pointer;
    }
    &__button {
      padding: 12.5px 26px;
      border-radius: 4px;
      color: #fff;
      width: 100%;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      white-space: nowrap;
      text-align: center;
      line-height: 17px;
      font-size: 14px;
      display: -moz-box;
      display: flex;
      -moz-box-align: center;
      align-items: center;
      -webkit-transition: all .1s ease-in-out;
      -moz-transition: all .1s ease-in-out;
      transition: all .1s ease-in-out;
      outline: none;
      background-color: #4D7F70;
      border: 1px solid #4D7F70;
      &:hover {
        -webkit-box-shadow: 0 4px 12px rgb(41 136 239 / 40%);
        box-shadow: 0 4px 12px rgb(41 136 239 / 40%);
      }
      &-disabled {
        background: gray!important;
        border: 1px solid gray!important;
        &:hover {
          -webkit-box-shadow: none;
          box-shadow: none;
        }
      }
    }
}

.error {
    color: #fc4b4e;
    visibility: hidden;
}

.showError {
    visibility: visible;
}

.errorBorder {
    border: 1px solid #fc4b4e !important;
}

.self-input {
    & > textarea {
        &::placeholder {
            color: #7b61ff;
        }
        &::-moz-placeholder {
            color: #7b61ff;
        }
        &::-webkit-input-placeholder {
            color: #7b61ff;
        }
        &::-ms-input-placeholder {
            color: #7b61ff;
        }
    }
    & > input {
        &::placeholder {
            color: #7b61ff;
        }
        &::-moz-placeholder {
            color: #7b61ff;
        }
        &::-webkit-input-placeholder {
            color: #7b61ff;
        }
        &::-ms-input-placeholder {
            color: #7b61ff;
        }
    }
}
.colored-text {
  color: #4D7F70;
}
