.fee-transaction {
  width: 100%;
  border: 1px solid #E0E6ED;
  border-radius: 10px;
  padding: 0 20px;
  margin-top: 12px;
  cursor: pointer;
  &:hover {
    background: #F5F8F7;
  }
  > .ant-row {
    height: 72px;
  }
  &__profile {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__logo {
    img {
      width: 16px;
      height: 16px;
    }

    &.user-transactions {
      display: flex;
      justify-content: center;
    }
  }
  &__type {
    font-weight: 600;
    font-size: 14px;
    color: #0770DC;
  }
  &__date {
    font-weight: 600;
    font-size: 14px;
    color: #3B4857;
  }
  &__status {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    &:before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      margin-right: 4px;
    }
    &_done {
      color: #22b84c;
      &:before {
        background: #22b84c;
      }
    }
    &_pending {
      color: #ff9800;
      &:before {
        background: #ff9800;
      }
    }
    &_failed {
      color: #eb5757;
      &:before {
        background: #eb5757;
      }
    }
  }
  &__amount {
    text-align: right;
    &_usd {
      font-size: 14px;      
      color: #818A98;      
    }
    &_tokens {
      font-weight: 600;
      font-size: 14px;
      color: #3B4857;
    }
  }

  &__error-wrapper {
    position: relative;
    padding-left: 12px;
    margin-bottom: 19px;
    &:after {
      content: '';
      display: block;
      width: 3px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(235, 87, 87, 0.4);
      border-radius: 1.5px;
    }
  }

  &__error-title {
    font-weight: 600;
    font-size: 12px;
    color: #000;
  }
  &__error-description {
    font-size: 12px;
    color: #818A98;
  }

  &__details {
    overflow: hidden;
    position: relative;
    font-size: 12px;
    height: 0;
    padding-top: 0;
    padding-left: 6px;
    padding-right: 6px;
    transition: height .3s ease,
                padding-top .3s ease;
    &_opened {
      height: 223px;
      padding-top: 14px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 15px;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background: #E0E6ED;
    }
    b {
      font-weight: 600;
    }
    &-id {
      margin-top: 24px;
    }
    &-to {
      margin-top: 10px;
    }
    &-from {
      margin-top: 2px;
    }
    &-fee {
      margin-top: 10px;
    }
    &-click-id {
      margin-top: 10px;
    }
    &-hash {
      margin-top: 2px;
      span {
        color: #4D7F70;
      }
    }
  }
}