.fee-wallets {
  width: 739px;
  &__filters {
    display: flex;
    gap: 40px;
    margin-top: 40px;
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  &__add-new-wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 33px;
    .admin-button {
      width: 142px;
    }
  }
  &__load-more {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}