.admin-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  width: 188px;
  height: 36px;
  left: 902px;
  top: 647px;
  border: 1px solid;
  border-radius: 6px;
  cursor: pointer;
  transition: opacity .3s ease;
  &:hover {
    opacity: .8;
  }
  &__primary {
    background: #4D7F70;
    border-color: #4D7F70;
    color: #fff;
  }
  &__secondary {
    background: #fff;
    border-color: #D3DCE6;
    color: #818A98;
  }
  &__spin {
    color: #fff;
    margin-right: 0;
    width: 0;
    opacity: 0;
    transition: margin-right 0.3s ease, width 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
  }
  &_loading {
    opacity: 0.7;
    cursor: default;
    .admin-button__spin {
      opacity: 1;
      margin-right: 7px;
      width: 14px;
    }
    &:disabled {
      background: #4D7F70;
      color: #fff;
    }
  }
  .text {
    display: flex;
    align-items: center;
  }
}