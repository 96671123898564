@import url("./rangePicker.scss");

.form-field {
  box-sizing: border-box;
  border-radius: 6px;
  input {
    padding: 0 15px;
  }
  &__form-item {
    margin-bottom: 0;
  }
  &__text, &__password {
    width: 268px;
    height: 30px;
  }
  &__phone {
    .react-tel-input .form-control {
      width: 268px;
      height: 30px;
      padding: 10px 16px 10px 40px;
      color: var(--color-darkGray);
      font-size: 12px;
      font-weight: 400;
      background: var(--color-white);
      border: 1px solid #D3DCE6;
      box-sizing: border-box;
      border-radius: 6px;
      &:focus {
        outline: none;
      }
    }
    .react-tel-input .flag-dropdown {
      border: none;
      border-right: none !important;
      background: var(--color-white);
    }
    .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus,
    .react-tel-input .flag-dropdown.open .selected-flag {
      background: transparent;
    }
  }
  &__search {
    width: 256px;
    height: 36px;
    position: relative;

    .ant-input {
      font-size: 12px;
      color: #8492A5;

      &:focus {
        background-color: rgb(232,240,254);
      }
    }
    .ant-input-affix-wrapper {
      border-radius: 6px !important;
      &:hover {
        border-color: #4D7F70;
      }
    }
    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
      border-color: #4D7F70;
      box-shadow: 0 0 0 2px rgb(77 127 112 / 20%);
    }
    .ant-input-affix-wrapper-disabled {
      background: transparent;
    }
    .ant-input-suffix {
      margin-right: 32px;
      margin-top: 1px;
      .anticon-search {
        margin-left: 0;
      }
    }
    .ant-input-group-addon {
      width: auto;
      position: absolute;
      top: 2px;
      right: 1px;
      background: transparent;
      padding: 0;
      border: none;
    }
    .ant-input-search-icon:before {
      display: none;
    }
    .form-field__reset-button {
      width: 32px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover,
      &:focus {
        background: none;
        box-shadow: none;
      }
      &:after {
        display: none;
      }
    }
  }
  &__percent-input,
  &__fiat-amount-input {
    width: 170px;
    height: 42px;
    line-height: 42px;
    font-size: 14px;
    color: #3B4857;
    &.ant-input-number:hover {
      border-color: #4D7F70;
    }
    &.ant-input-number:focus, &.ant-input-number-focused {
      border-color: #4D7F70;
      box-shadow: 0 0 0 2px rgb(77 127 112 / 20%);
    }
    .ant-input-number-handler-wrap {
      display: none;
    }
  }
  &__range-picker-wrapper {
    width: 225px;
    height: 36px;
    position: relative;
    .ant-row.ant-form-item, .ant-form-item-control-input, .ant-form-item-control-input-content {
      width: 100%;
      height: 100%;
    }
  }
  &__range-picker {
    width: 100%;
    height: 100%;
    &-placeholder {
      position: absolute;
      top: 50%;
      left: 37px;
      transform: translateY(-50%);
      margin-top: 1px;
      pointer-events: none;
      font-size: 12px;
      color: #818A98;
      background: #fff;
    }
    .ant-picker-active-bar {
      margin-left: 26px;
    }
    input {
      padding: 0 10px;
      font-size: 12px;
      color: #818A98;
    }
    &:before {
      content: '';
      display: block;
      background: url("../../../img/date-picker.svg");
      width: 38px;
      height: 16px;
    }
  }
  &__select {
    border: none;
    font-size: 12px;
    &.ant-select {
      width: 142px;
    }
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 36px;
      border-color: #D3DCE6;
      background: transparent;
      border-radius: 6px;
    }
    &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #4D7F70;
    }
    &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: 0 0 0 2px rgb(77 127 112 / 20%);
    }
    &.ant-select-single .ant-select-selector .ant-select-selection-item, &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
    &.search-email {
      .ant-select-selection-placeholder {
        color: #818A98;
        opacity: 1;
      }
    }
  }
}