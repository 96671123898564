.currency-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 74px;
  border: 1px solid #E0E6ED;
  border-radius: 10px;
  padding: 10px 14px;
  cursor: pointer;
  transition: background .3s ease;
  &_active {
    background: #F5F8F7;
  }
  &:not(:first-of-type) {
    margin-top: 12px;
  }
  &:hover {
    background: #F5F8F7;
  }
  &_left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 48%;
  }
  &_right {
    text-align: right;
    max-width: 52%;
  }
  &__name-wrap {
    display: flex;
    align-items: center;
  }
  &__logo {
    width: 20px;
    height: 20px;
  }
  &__title {
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    margin-left: 10px;
    @media screen and (max-width: 1091px) {
      font-size: 12px;
    }
  }
  &__wallet {
    color: #4D7F70;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 7px;
    @media screen and (max-width: 1091px) {
      font-size: 12px;
    }
  }
  &__usd {
    font-size: 14px;
    color: #818A98;
    margin-top: 7px;
    @media screen and (max-width: 1091px) {
      font-size: 12px;
    }
  }
  &__amount {
    font-weight: 600;
    font-size: 14px;
    color: #273443;
    overflow-wrap: anywhere;
    @media screen and (max-width: 1091px) {
      font-size: 12px;
    }
  }
}