.login-options {

    &__google-button {
      display: flex !important;
      align-items: center;
      justify-content: center;
      gap: 5px;
      width: 100% !important;
      height: 36px !important;
      margin: 0 auto;
      border-radius: 4px !important;
      box-shadow: none !important;
      font-family: inherit !important;
      overflow: hidden;
      cursor: pointer;
  
      > div:first-of-type {
        height: 30px !important;
        width: 30px !important;
        > svg {
          height: 30px !important;
          width: 30px !important;
          transform: scale(1.5);
        }
      }
  
      > span {
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        color: #818A98 !important;
      }

      &.metamask {
        cursor: pointer;
        margin-top: 12px;
        display: none !important;
        font-size: 13px;
        background-color: #fff;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #818A98;
        &:not(&.profile) {
          border: none;
        }

        @media screen and (min-width: 1024px){
          display: flex !important;
        }

        &.profile {
          height: 34px !important;
          margin: 0;
          padding: 2px;
          display: none !important;
          border: 1px solid #d3dce6!important;

          @media screen and (min-width: 1024px){
            display: flex !important;
          }

          img {
            height: 25px;
          }
        }
      }
    }
  }

  .kep-login-facebook.metro {
    border-radius: 2px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 41px;
    width: 238px;
    margin: 12px auto 0;
    text-transform: none;
    font-size: 14px;
  }


  