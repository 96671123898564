.swap-confirm-banner {
  width: 100%;
  max-width: 790px;
  margin: 20px auto 0;
  background: #E9F3FE;
  border: 1px solid #679A8A;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 590px) {
    flex-direction: column;
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__icon {
    margin-right: 20px;
    @media screen and (max-width: 590px) {
      display: none;
    }
  }
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #404040;
    @media screen and (max-width: 590px) {
      text-align: center;
    }
  }
  &__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #575757;
    @media screen and (max-width: 590px) {
      text-align: center;
      margin-top: 10px;
    }
  }
  &__button {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding: 21px 39px;
    background-color:#191A1D;
    color: white; 
    @media screen and (max-width: 590px) {
      margin: 20px auto 0;
    }
  }
}

.swap-confirm__wrapper {
  padding-bottom: 50px;
  margin-top: 20px;
}

.swap-confirm-modal {
  max-width: 400px;
  width: 100% !important;
  .ant-modal-content {
    border-radius: 10px;
    margin: 0 10px;
  }
  .ant-modal-header, .ant-modal-close {
    display: none;
  }
  .ant-modal-body {
    padding: 24px 20px 30px;
  }
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #404040;
  }
  &__loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 85px 0;
  }
  &__icon {
    margin-top: 34px;
    svg {
      display: block;
      margin: 0 auto;
    }
  } 
  &__status {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: #404040;
    margin-top: 20px;
  }
  &__message {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #A0A0A0;
    margin-top: 20px;
    white-space: pre-line
  }
  &__fail-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    button {
      width: 140px;
      height: 42px;
      border-radius: 8px;
    }
    &-retry {
      color:#191A1D;
      background-color: white; 
    }
    &-back {
      background-color:#191A1D;
      color: white; 
    }
  }
  &__success-buttons {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    button {
      background-color:#191A1D;
      color: white; 
      width: 140px;
      height: 42px;
      border-radius: 8px;
    }
  }


  &__error-maximum {
    margin-bottom: 0;
    font-size: 14px;
    &:nth-of-type(2),
    &:nth-of-type(6){
      color: #3B4857;
      margin-bottom: 10px;
    }

    &:nth-of-type(4){
      color: #679A8A;
      margin-bottom: 10px;
    }
  }

  &__error-miner {
    margin-bottom: 0;
    font-size: 14px;

    &:nth-of-type(2),
    &:nth-of-type(5){
      color: #3B4857;
      margin-bottom: 10px;
    }
  }
}

.swap-confirm {
  padding-bottom: 10px;
  &__value {
    display: flex;
    width: 100%;
    background: #F5F8F7;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    @media screen and (max-width: 590px) {
      flex-direction: column;
      align-items: flex-start;
      margin: 20px -10px;
      padding: 20px 15px;
    }
    &-wrap {
      display: flex;
    }
    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      color: #A0A0A0;
      @media screen and (max-width: 590px) {
        margin-bottom: 10px;
      }
    }
    &-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background: #F9F9FB;
      border-radius: 8px;
      margin-right: 10px;
      img {
        width: 20px;
        height: 20px;
      }
      @media screen and (max-width: 590px) {
        width: 24px;
        height: 24px;
        border-radius: 5px;
        img {
          width: 12px;
          height: 12px;
        }
      }
    }
    &-amount, 
    &-currency {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      @media screen and (max-width: 590px) {
        font-weight: 600;
        font-size: 14px;
      }
    }
    &-amount {
      color: #404040;
      margin-right: 10px;
      word-break: break-all;
    }
    &-currency {
      color: #A0A0A0;
    }
    &-arrow {
      margin: 30px 30px 7px;
      @media screen and (max-width: 590px) {
        display: none;
      }
    }
  }
  &__get {
    @media screen and (max-width: 590px) {
      margin-top: 20px;
    }
  }
  &__rate {
    margin-top: 20px;
    &-flex {
      display: flex;
    }
    &-info {
      display: flex;
    }
    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #404040;
      margin-bottom: 10px;
    }
    &-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      background: #F9F9FB;
      border-radius: 5px;
      margin-right: 5px;
      img {
        width: 12px;
        height: 12px;
      }
    }
    &-amount,
    &-currency {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
    }
    &-amount {
      color: #404040;
      margin-right: 5px;
      word-break: break-all;
    }
    &-currency {
      color: #A0A0A0;
    }
    &-divider {
      margin-left: 10px;
      margin-right: 15px;
    }
  }
  &__partner-info {
    display: flex;
    margin-top: 30px;
    &-name {
      margin-right: 60px;
    }
    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #404040;
      margin-top: 15px;
    }
    &-data {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #3B4857;
      img, svg {
        max-width: 16px;
        max-height: 16px;
        margin-right: 5px;
      }
    }
  }
}