.admin-users {
  width: 1265px;
  &__filters {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 15px;
  }
  &__button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__add-user-button {
    width: 142px;
    height: 36px;
    margin-bottom: 20px;
    cursor: pointer;

    &__download-emails {
      color: #4D7F70;
      background-color: white;
      border: 1px solid #4D7F70;
      border-radius: 4px;
      padding: 14px 10px;
      margin-bottom: 0;
      transition: all 0.3 ease-in-out;

      &:hover {
        background: #DCEBE5;
        border: 1px solid transparent;
      }

      .admin-users__loader {
        margin-bottom: 0;
        height: auto;
      }
    }
  }
  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .ant-pagination-item-active, 
    .ant-pagination-item-active a, 
    a:hover {
      color: #4D7F70;
      border-color: #4D7F70;
    }
    .ant-pagination-prev:focus .ant-pagination-item-link, 
    .ant-pagination-next:focus .ant-pagination-item-link, 
    .ant-pagination-prev:hover .ant-pagination-item-link, 
    .ant-pagination-next:hover .ant-pagination-item-link {
      color: #4D7F70;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #4D7F70;
    }
    .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: 0 0 0 2px rgb(77 127 112 / 20%);
      border-color: #4D7F70;
    }
  }
}
