.multisend-recipients {
  margin-top: 16px;
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }
  &__table {
    margin-top: 17px;
    &-labels {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #818A98;
      padding: 0 20px;
    }
    &-data-wrapper {
      max-height: 120px;
      overflow: auto;
    }
    &-data {
      position: relative;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #3B4857;
      padding: 12px 20px;
      border-radius: 4px;
      transition: background .3s ease;
      @media screen and (max-width: 768px) {
        word-break: break-all;
      }
      &:hover {
        background: #FBFAFD;
      }
      &:not(:last-of-type) {
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          position: absolute;
          left: 0;
          bottom: 0;
          background: #E0E6ED;
          border-radius: 2px; 
        }
      }
      &-remove {
        text-align: right;
        color: #238AF6;
        cursor: pointer;
      }
    }
  }
}