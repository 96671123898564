.ant-layout-sider {
  .sider {
    min-height: 611px;
    height: calc(100vh - 64px);
    position: relative;
    .sider__nav-title {
      transition: visible 0.3s ease, opacity 0.3s ease;
    }
    &__regular {
      .sider__nav-title {
        visibility: visible;
        opacity: 1;
      }
    }
    &__collapsed {
      .sider__nav-title {
        visibility: hidden;
        opacity: 0;
      }
    }
    &__nav-item {
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;
      height: 50px;
      overflow: visible;
      &:first-child {
        margin-top: 50px;
      }
      &:not(:last-child) {
        margin-bottom: 44px;
      }
      &_float,
      &_float.ant-menu-item-selected.ant-menu-item-selected {
        width: 100%;
        position: absolute;
        bottom: 37px;
        left: 50%;
        transform: translateX(-50%);
      }
      &.ant-menu-item-selected {
        position: relative;
        background: transparent;
      }
    }
    &__nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #273443;
      &-icon {
        display: block;
      }
      &-icon_active {
        display: none;
      }
      &_active,
      &:hover {
        color: #4D7F70;
        .sider__nav-link-icon {
          display: none;
        }
        .sider__nav-link-icon_active {
          display: block;
        }
        &:after {
          content: "";
          display: block;
          width: 2px;
          height: 32px;
          position: absolute;
          right: 0;
          top: -5px;
          background: #4D7F70;
          border-radius: 5px 0px 0px 5px;
        }
      }
    }
  }
}

.sider__nav-tooltip_regular {
  display: none !important;
}
