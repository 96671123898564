.wallets-page {
  .wallet {
    padding: 10px 0;
    font-size: 12px;
    &:not(:last-of-type) {
      border-bottom: solid 1px rgba(211, 220, 230, 0.6);
    }
    &__expand {
      display: flex;
      align-items: center;
      &_opened svg {
        transform: rotateZ(180deg);
        path {
          fill: #2988EF;
        }
      }
      svg {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
    &__icon {
      img {
        width: 30px;
        height: 30px;
      }
    }
    &__address {
      color: #0770dc;
      text-overflow: ellipsis;
      overflow: hidden;
      &:hover {
        cursor: pointer;
      }
      &-copy {
        display: inline;
        cursor: pointer;
      }
    }
    &__currency-amount span {
      display: block;
    }
    &__usd-amount span {
      display: block;
    }
    &__key span {
      display: block;
    }
    &__status {
      display: flex;
      align-items: center;
      &:before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        margin-right: 4px;
      }
      &_default {
        color: #22b84c;
        &:before {
          background: #22b84c;
        }
      }
      &_secondary {
        color: #fc4b4e;
        &:before {
          background: #fc4b4e;
        }
      }
    }
    &__options {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
    &__secondary {
      .wallet__address {
        color: #8492a5;
      }
    }
    &__min-replenish {
      display: inline-block;
      margin-top: 10px;
      padding: 6px 10px;
      background: #E9F8EE;
      border-radius: 4px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #3B4857;
    }
  }
  .wallet-tokens {
    height: 0;
    overflow: hidden;
    &_opened {
      height: auto;
    }
    &__item {
      margin-top: 20px;
    }
    &__icon {
      width: 30px;
      height: 30px;
    }
    &__address {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  } 
}
