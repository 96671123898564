.modal {
  top: 176px;
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    padding: 20px 24px;
    border-radius: 8px 8px 0 0;
    background:  #F5F8F7;
  }
  .ant-modal-body {
    padding: 20px 111px 60px;
    @media screen and (max-width: 768px) {
      padding: 15px;
    }
  }
  .ant-modal-close {
    height: 63px;
  }
  &__text {
    text-align: center;
    margin-bottom: 32px;
  }
  &__tip {
    color: #273443;
    font-size: 12px;
  }
  &__warning {
    margin-top: 20px;
    font-size: 10px;
    &_red {
      display: inline-block;
      margin-right: 2px;
      color: #eb5757;
    }
  }
  &__buttons-wrapper {
    margin-top: 40px;
  }
  &__button {
    display: block;
    width: 144px;
    height: 34px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    box-shadow: none;
    background: #191A1D;
    border-color: #191A1D;
    &:hover, &:focus {
      border-color: #303030;
      background: #303030;
    }
    &-next {
      width: 100%;

      &:disabled {
        background-color: #A0A0A0;
        color: white;
        border: none;
        cursor: not-allowed;
      }
    }
    &-secondary {
      width: 100%;
      background-color: transparent;
      border: 1px solid#191A1D;
      color: #191A1D;
      &:hover, &:focus {
        border-color: transparent;
        background: #303030;
        color: white
      }

      &:disabled {
        background-color: #A0A0A0;
        color: white;
        border: none;
        // cursor: not-allowed;
      }
    }
    &-center {
      margin: 0 auto;
    }
    &-cancel {
      margin-left: auto;
      color: #eb5757;
      background: transparent;
      border: 1px solid #eb5757;
      transition: background 0.3s ease, color 0.3s ease;
      &:hover:not(:disabled) {
        background: #eb5757;
        border: 1px solid #eb5757;
        color: #fff;
      }
    }
  }
  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modal-form {
  &.ant-form label {
    font-size: 12px;
  }
  &__label {
    margin-bottom: 20px;
    .ant-form-item-label {
      font-size: 12px;
      font-weight: 600;
      padding-bottom: 0;
      label {
        font-size: 12px;
        height: auto;
      }
    }

  }
  &__input {
    width: 100%;
    height: 36px;
    border-radius: 3px;
    font-size: 12px;
    &:hover {
      border-color: #d9d9d9;
    }
    &:focus {
      border-color: #d9d9d9;
      box-shadow: none;
    }
  }
  &__checkbox {
    display: flex;
    flex-direction: column;
    label {
      margin-top: 12px;
    }
  }
  &__description {
    font-size: 12px;
    color: #5f6e80;
  }
  &__chars-limit {
    font-size: 12px;
    color: #8491A5;
    position: absolute;
    top: 10px;
    right: 15px;
  }
}
