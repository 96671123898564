.dashboard-table-data {
    width: 100%;
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
    }
    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #273443;
        margin-bottom: 0;
    }
    &__table-content {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 6px;
        padding: 11px 30px;
    }
    &__table {
        & th {
            width: 0.1%;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 25px;
            color: #3b4857;
            background: #ffffff;
            border-right: 40px solid transparent;
        }
        & > tr:first-child {
            border-bottom: 13px solid transparent;
            & td {
                cursor: pointer;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 25px;
                color: #3b4857;
                &:not(:last-child) {
                    border-right: 1px solid #d3dce6;
                }
            }
        }
        & > tr:not(:first-child) {
            background: #fbfafd;
        }
        & td {
            padding: 10px 5px;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            color: #818a98;
        }

        &.users  {
            tr:first-child {
                > td {
                    cursor: pointer;
                }
            }
            
        }
    }
    &__total {
        display: flex;
        align-items: center;
        &-title {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 25px;
            color: #3b4857;
            padding: 18px 0px;
            margin-right: 65px;
        }
        &-count {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #4D7F70;
        }
    }
    &__info {
        display: inline-block;
        margin-top: 30px;
        padding: 8px 16px;
        color: #4D7F70;
        background: #fbfafd;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        border-radius: 4px;
    }
}

.admin-dashboard-container-radio-button {
    .ant-radio-wrapper {
        .ant-radio-inner {
          border-color: #4D7F70;
          &:after {
            background-color: #4D7F70;
          }
        }
        &.ant-radio-wrapper-checked {
          .ant-radio-inner {
            border-color: #4D7F70;
          }
        }
        .ant-radio-input:focus + .ant-radio-inner {
          box-shadow: 0 0 0 2px rgb(77 127 112 / 20%);
        }
        .ant-radio-checked::after {
          border-color: #4D7F70;
        }
      }
}