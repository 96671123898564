.form-create-merchant {
  &__comission-info {
    display: flex;
    width: 100%;
    padding: 6px 14px;
    margin-top: 20px;
    background: #f9fafd;
    border-radius: 4px;
    font-size: 12px;
    color: #5f6e80;
  }
  &__fee-docs {
    display: block;
    font-weight: bold;
    font-size: 12px;
    color: #238af6;
    margin-top: 16px;
    margin-left: 14px;
    margin-bottom: 20px;
  }
}
