.modal-delete-merc {
  .ant-modal-header {
    display: none;
  }
  .ant-modal-body {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .ant-modal-close {
    display: none;
  }
  &__buttons-wrapper {
    margin-top: 0;
  }
}
