.admin-currencies-dropdown {
  width: 100%;
  max-width: 159px;
  height: 36px;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #D3DCE6;
  border-radius: 6px;
  margin: 0 auto;
  padding-right: 45px;
  &__arrow {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
    cursor: pointer;
    &_opened {
      transform: translateY(-50%) rotateZ(180deg); 
    }
  }
  &__selected {
    display: flex;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: center;
    padding: 0 14px;
    font-size: 12px;
    cursor: pointer;
    &-item {
      padding: 0;
      width: auto;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
  }
  &__list {
    position: absolute;
    z-index: 1;
    top: calc(100% + 1px);
    left: -1px;
    width: 100%;
    box-sizing: content-box;
    background: #FFFFFF;
    border: 1px solid #F0F8FF;
    border-radius: 0px 0px 10px 10px;
    overflow-x: hidden;
    padding-bottom: 10px;
    max-height: 385px;
  }
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 24px 10px 14px;
    width: 100%;
    min-height: 36px;
    font-size: 12px;
    color: #000000;
    cursor: pointer;
    &_hidden {
      display: none;
    }
    &:hover {
      background: #F5F8F7;
    }
  }
  &__logo {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  &__tick {
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
    color: #000;
  }
  &__title {
    width: 100%;
  }
  &__search {
    input {
      display: block;
      width: 100%;
      height: 36px;
      background: #F9F9F9;
      border-radius: 0px 0px 6px 6px;
      border: none;
      outline: none;
      padding: 0 18px;
    }
  }
}