.admin-users {
  &__list {
    margin-top: 40px;
    &-row {
      display: flex;
      align-items: center;
      &.admin-users__list-row_legend {
        margin-bottom: 13px;
      }
      &:not(.admin-users__list-row_legend) {
        margin-top: 2px;
        min-height: 61px;
        margin-left: -20px;
        margin-right: -20px;
        padding: 17px 20px 0;
        transition: background 0.3s ease;
        cursor: pointer;
        &:hover {
          background: #F5F8F7;
        }
      }
      // .admin-users__list-item {
      //   &:nth-of-type(7) {
      //     display: flex;
      //     justify-content: center;
      //   }
      // }
    }
    &-item {
      display: block;
      overflow-wrap: break-word;
      &:nth-of-type(1) {
        width: 120px;
      }
      &:nth-of-type(2) {
        width: 310px;
        .text {
          max-width: 260px;
        }
      }
      &:nth-of-type(3) {
        width: 290px;
      }
      &:nth-of-type(4) {
        width: 240px;
      }
      // &:nth-of-type(5) {
      //   width: 130px;
      // }
      &:nth-of-type(5) {
        width: 100px;
      }
      &:nth-of-type(6) {
        width: 140px;
        display: flex;
        justify-content: center;
        // padding-left: 20px;
        
        // .text {
        //   display: block;
        //   margin-right: 10px;
        // }
      }
      &:nth-of-type(7) {
        flex: auto;
      }
    }
     .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .sorting-icon {
      cursor: pointer;
    }
  }
  &__email {
    padding-right: 10px;
  }
  &__balance {
    .text {
      margin-right: 10px;
    }
  }

  &__color-status {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 10px;
    & + .text {
      display: inline-block;
    }
    &_active {
      background: #22b84c;
    }
    &_inactive {
      background: #e85b48;
    }
  }
  &__actions {
    display: block;
    width: 22px;
    height: 22px;
    line-height: 9px;
    text-align: center;
    font-size: 22px;
    cursor: pointer;
  }
  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    &-title {
      font-size: 16px;
      color: #818a98;
    }
    &-image {
      margin-top: 45px;
    }
  }
  &__loader {
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 70px;
  }

  .sorting-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  .sorting-icon {
    &:hover,
    &:active {
      background: #F2F8FF;
    }

    &-clicked {
      background: #F2F8FF;
    }
  }
}
