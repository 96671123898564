.metamask-form {
  width: 311px;

  &__wrapper {
    > div.ant-form-item {
      display: flex;
      flex-direction: column;

      > div:first-of-type {
        align-self: flex-start;
        > label {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #273443;
        }
      }

      > div:nth-of-type(2) {
        width: 311px;
      }
    }

    &.message {
      position: relative;
      margin-bottom: 30px;

      .static-text {
        position: absolute;
        top: 37px;
        z-index: 1;
        left: 12px;
      }
    }
  }

  &__input-message {
    textarea {
      padding: 25px 10px;
    }
  }

  &__button {
    width: 146px;
    height: 36px;
    border: 1px solid #d3dce6;
    border-radius: 4px;
    background-color: white;

    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    cursor: pointer;

    &.submit {
      background: #2988ef;
      color: white;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  &__loader {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
