.user-edit {
  width: 740px;
  &__section {
    margin-top: 30px;
  }
  &__inputs-wrap {
    margin-top: 20px;
  }
}

.user-edit-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 40px;
}