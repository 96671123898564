.header {
  max-height: 60px;
  min-height: 60px;
  padding: 18px 33px;
  background: #ffffff;
  z-index: 1;
  box-shadow: 2px 4px 16px rgba(115, 129, 143, 0.16);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 10;
  @media screen and (max-width: 768px) {
    padding: 18px 15px;
  }

  &__left {
    display: flex;
    align-items: center;
    column-gap: 65px;
    @media screen and (max-width: 768px) {
      column-gap: 40px;
    }
  }
  &__right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    column-gap: 30px;
    row-gap: 10px;
    justify-content: flex-end;
  }
  &__toggle-sider {
    user-select: none;
    cursor: pointer;
  }
  &__controls-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 10px;
    @media screen and (max-width: 768px) {
      &:not(.header__release-notes) {
        display: none;
      }
    }
  }
  &__release-notes {
    margin-right: 10px;
  }
  &__merc-button {
    padding: 9px 29px;
    display: flex;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
    color: #4D7F70;
    border: solid 1px #4D7F70;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    transition: background 0.3s ease;
    padding: 0 15px;
    line-height: 34px;
    cursor: pointer;
    &:hover {
      img {
        transform: rotate(180deg);
      }
    }
    img {
      transition: all 0.3s ease;
    }
  }
  &__user {
    height: 64px;
    display: flex;
    align-items: center;
  }
  &__user-name {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-weight: 600;
    font-size: 14px;
    color: #273443;
    cursor: pointer;
  }
  &__dropdown {
    width: 100%;
    &-item {
      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
      a {
        color: #3b4857;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
