.swap-history-item-mobile {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #3B4857;
  border-radius: 8px;
  transition: background .5s ease;
  &__main-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  &__id {
    width: 144px;
  }
  &__currency {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__currency-item {
    display: flex;
    align-items: center;
    svg {
      display: block;
      margin-right: 5px;
    }
  }
  &__currency-name {
    color: #818A98;
    margin-left: 5px;
  }
  &__status {
    &_done {
      span {
        color: #23B852;
      }
    }
    &_pending {
      span {
        color: #F99400;
      }
    }
    &_failed {
      span {
        color: #E85B48;
      }
    }
  }

}