.multisend-summary {
  margin-top: 16px;
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }
  &__table {
    margin-top: 10px;
  }
  &__cell {
    background: #FAF9FC;
    padding: 7px 20px;
    height: 100%;
    &-united,
    &-united &,
    &-united .ant-row,
    &-united .ant-col {
      height: 100%;
    }
    &-united & {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  &__value {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #3B4857;
  }
  &__name {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #818A98;
  }
}