.admin-fee-withdraw {
  width: 740px;
  &__tabs-switcher {
    display: flex;
    gap: 40px;
    margin-top: 30px;
    &-item {
      position: relative;
      cursor: pointer;
      &_active {
        .text {
          color: #3B4857;
        }
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 3px;
          position: absolute;
          top: calc(100% + 5px);
          left: 0;
          background: #4D7F70;
        }
      }
    }
  }
  &__load-more {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}