.main-layout {
  height: 100%;
  &__container {
    display: flex;
    height: calc(100% - 60px);
  }
  &__content-wrap {
    display: flex;
    flex: 1;
    padding: 20px 32px;
    height: 100%;
    overflow: auto;
    padding-bottom: 0px;
    overflow-x: hidden;
    @media screen and (max-width: 767px) {
      padding: 20px 10px;
    }
  }
  &__content {
    width: 100%;
    // max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ant-row {
      flex: 1 0 auto;
    }

    .swap-history-filters__label,
    .swap-offers-filters__label {
      &.ant-row {
        flex: none;
      }
    }
  }
  &__box-shadow {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    background: #000000;
    opacity: 0.4;
    z-index: 9;
    // transition: all 0.3s ease-in-out;
    &-opened {
      width: 100%;
    }
  }
}
