.swap-offers-update-banner {
  width: 100%;
  max-width: 790px;
  margin: 20px auto 0;
  background: #DCEBE5;
  border: 1px solid #679A8A;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 591px) {
    z-index: 1;
    position: sticky;
    top: 202px;
    outline: solid 10px #f1f3f6;
    &::before, &:after {
      content: '';
      position: absolute;
      background: #f1f3f6;
      width: calc(100% + 2px);
      height: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
    &::before {
      bottom: calc(100% + 1px);
    } 
    &:after {
      top: calc(100% + 1px);
    }
  }

  @media screen and (max-width: 590px) {
    flex-direction: column;
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__icon {
    margin-right: 20px;
    @media screen and (max-width: 590px) {
      display: none;
    }
  }
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #3B4857;
    @media screen and (max-width: 590px) {
      text-align: center;
    }
  }
  &__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #818A98;
    @media screen and (max-width: 590px) {
      text-align: center;
      margin-top: 10px;
    }
  }
  &__button {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding: 21px 39px;
    @media screen and (max-width: 590px) {
      margin: 20px auto 0;
    }
  }
  &__dots-loader:after {
    animation: dots 2s linear infinite;
    content: '';
  }

  @keyframes dots {
    0%, 20% {
      content: '.';
    }
    40% {
      content: '..';
    }
    60% {
      content: '...';
    }
    90%, 100% {
      content: '';
    }
  }
}

.swap-offers {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 790px;
  margin: 20px auto 0;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 10px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 590px) {
      flex-wrap: nowrap;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 14px;
    color: #818A98;
    width: 100%;
  }
  &__list {
    margin-top: 17px;
  }
  &__loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #9B9FB8;
    span {
      margin-top: 20px;
    }
  }
}