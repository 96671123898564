.welcome-modal {
  position: relative;
  .ant-modal-body {
    padding: 85px 210px 80px;
    @media screen and (max-width: 1023px) {
      padding: 85px 10px 80px;
    }
  }
  &__logo {
    position: absolute;
    top: 38px;
    left: 38px;
  }
  &__title {
    display: block;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #273443;
  }
  &__text {
    font-size: 12px;
    text-align: center;
    color: #3b4857;
    margin-top: 11px;
  }
  &__button {
    display: block;
    width: 265px;
    margin: 0 auto;
    margin-top: 20px;
    &:last-of-type {
      margin-top: 10px;
    }
  }
}
