.home-currencies {
  display: flex;
  flex-direction: column;
  &__content {
    background: #fff;
    border: 1px solid #e0e6ed;
    border-radius: 10px;
    padding: 27px 20px 31px;
    @media screen and (max-width: 1023px) {
      padding: 27px 15px 31px;
    }
  }
  &__title {
    margin-left: 15px;
    font-weight: 600;
    font-size: 16px;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1023px) {
      margin-left: 0;
    }
  }
  &__select-all,
  &__hide-zero {
    font-size: 14px;
    color: #4D7F70;
    cursor: pointer;
    text-align: right;
    .ant-checkbox + span {
      padding-right: 0;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #4D7F70;
        border-color: #4D7F70;
      }
      &:after {
        border-color: #4D7F70;
      }
    } 
    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #4D7F70;
    }
  }
  &__hide-zero {
    margin-top: 5px;
  }
  &__items-wrapper {
    margin-top: 14px;
    @media screen and (max-width: 1023px) {
      overflow: scroll;
      height: 292px;
    }
  }
}
