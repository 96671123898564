.side-bar {
   min-height: calc(100% - 60px);
  background: #F5F8F7;
  padding: 40px 15px;
  transition: all 0.3s ease;
  width: 250px;
  @media screen and (max-width: 1359px) {
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 10;
  }
  &__buy {
    background-color: #191A1D;
    color: #ffffff;
    border: none;
    height: 36px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    &:hover {
      cursor: pointer;
    }
  }
  &__card {
    background-color: #F5F8F7;
    height: 36px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #22b84c;
    cursor: pointer;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &__mobile {
    color: #4D7F70;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    background-color: #4D7F70;
    border: none;
    border-radius: 5px;
    &-top {
      margin-top: 20px;
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
  }
  &__nav-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    white-space: nowrap;
  }
  &__nav-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    white-space: nowrap;
    gap: 20px;

    button {
      width: 100%;
    }
  }
  &__hide {
    @media screen and (max-width: 1359px) {
      display: none;
    }
  }
  &__link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 15px;
    border-radius: 8px;
    & > svg {
      min-width: 20px;
      min-height: 20px;
      transition: all 0.3s ease;
      margin-right: 18px;
    }
    & > span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #273443;
      transition: all 0.3s ease;
    }
    &:hover {
      & > svg {
        path,
        circle {
          stroke: #2C433E;
        }
      }
      & > span {
        color: #2C433E;
      }
      &_guide {
        height: 60px !important;
        img {
          width: 30px;
        }
      }
    }
  }
  &__link_active {
    background: #DCEBE5;
    & > svg {
      path,
      circle {
        stroke: #2C433E;
      }
    }
    & > span {
      color: #2C433E;
    }
  }
  &__closed {
    max-width: 80px;
    @media screen and (max-width: 1359px) {
      max-width: 0;
      padding: 40px 0;
    }
  }
  &__controls {
    overflow: hidden;
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
  .network-filter {
    margin-right: 0;
    width: 170px;
  }
  .header__merc-button {
    width: 170px;
    margin-top: 10px;
  }
  .header__user-name {
    display: none;
  }
  &__card-preorder {
    overflow: hidden;
    button {
      width: 100%;
      padding-top: 0;
    }
    &_icon {
      display: none;
      justify-content: center;
      align-items: center;
    }

    &.es {

      button {
        height: 45px;
      }
    }
  }
  &__guide {
    cursor: pointer;
  }
}