.swap-history {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  max-width: 790px;
  margin: 0 auto;
  padding: 20px 10px;
  background: #FFFFFF;
  border-radius: 10px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }
  &__title {
    font-weight: 700;
    font-size: 14px;
    color: #404040;
  }
  &__new {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    background: #000000;
    border-radius: 8px;
    padding: 11px 24px;
    transition: opacity .3s ease;
    &:hover {
      color: #fff;
      opacity: .9;
    }
  }

  &__legend {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #A0A0A0;
    padding: 0 10px;
    margin-top: 40px;
    @media screen and (max-width: 790px) {
      justify-content: space-between;
    }
    @media screen and (max-width: 590px) {
      display: none;
    }
    &-exchangeId {
      width: 144px;
      @media screen and (max-width: 790px) {
        width: 123px;
      }
    }
    &-date {
      width: 117px;
      @media screen and (max-width: 790px) {
        display: none;
      }
    }
    &-currency {
      width: 367px;
    }
  }
  &__list {
    overflow: auto;
    @media screen and (max-width: 590px) {
      margin-top: 20px;
    }
  }
  &__loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #9B9FB8;
    span {
      margin-top: 20px;
    }
  }
}