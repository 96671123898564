.transaction-offer {
  &__title {
    margin-top: 38px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }
  &__selector {
    margin-top: 22px;
    font-size: 14px;
    line-height: 19px;
    color: #3B4857;
  }
  &__select {
    width: 136px;
    height: 34px;
    .ant-select-selector {
      border: 1px solid #F0F8FF !important;
      box-sizing: border-box;
      box-shadow: 0px 4px 13px rgba(240, 248, 255, 0.37);
      border-radius: 5px !important;
    }
    .currency-item__logo {
      margin-right: 5px;
    }
  }
  &__select-option {
    .currency-item__logo {
      margin-right: 5px;
    }
  }
  &__wallet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 264px;
    height: 36px;
    margin-top: 30px;
    padding: 0 15px;
    background: #FFFFFF;
    border: 1px solid #F0F8FF;
    box-sizing: border-box;
    box-shadow: 0px 4px 13px rgba(240, 248, 255, 0.37);
    border-radius: 5px;
    font-size: 12px;
    color: #3B4857;
    .currency-item__logo {
      margin-right: 10px;
    }
  }
  &__wallet-icon {
    fill: #4D7F70;
    cursor: pointer;
  }
  &__loader {
    margin-top: 30px;
  }
}