.admin-dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  max-width: 1158px;
  & > div:not(:first-child) {
    margin-bottom: 20px;
  }

  &-radio-button {
    display: flex;
    width: 320px;
    justify-content: space-between;

    > p {
      margin-bottom: 0;
      font-weight: 600;
    }
  }

  .admin-marketing {
    &-container {
      width: 36%;
      margin-left: auto;
      position: relative;
    }

    &-form {
      display: flex;
      justify-content: space-between;

      &-error {
        position: absolute;
        top: 42px;
        left: 0;
        color: red;
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }
}
