.add-user-modal {
  &__input-wrap:not(:first-of-type) {
    margin-top: 20px;
  }
  &__radio {
    margin-top: 40px;
    .ant-radio-wrapper {
      .ant-radio-inner {
        border-color: #4D7F70;
        &:after {
          background-color: #4D7F70;
        }
      }
      &.ant-radio-wrapper-checked {
        .ant-radio-inner {
          border-color: #4D7F70;
        }
      }
      .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: 0 0 0 2px rgb(77 127 112 / 20%);
      }
      .ant-radio-checked::after {
        border-color: #4D7F70;
      }
    }
  }
  &__buttons-wrap {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    .admin-button {
      width: 94px;
    }
  }
}