.wallets-page {
  margin-top: 26px;
  &_fetching {
    display: none;
  }
}

.wallets__loader {
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wallets-search {
  width: 384px;
  height: 36px;
  position: relative;
  &__input {
    width: 100%;
    height: 100%;
    border: 1px solid #d3dce6;
    border-radius: 3px;
  }
  &__button {
    color: #3b4857;
    font-size: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}

.wallets-reset {
  cursor: pointer;
}

.wallets-create {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 36px;
  border: 1px solid #4D7F70;
  border-radius: 3px;
  color: #4D7F70;
  cursor: pointer;
  img {
    margin-right: 5px;
  }
}

.wallets-sorting {
  width: 160px;
  .ant-select-selector {
    min-height: 36px;
    display: flex;
    align-items: center;
  }
  .ant-select-selection-placeholder {
    opacity: 1;
  }
}

.pagination {
  margin-top: 23px;
  .ant-pagination-item-active {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
  &__info {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 12px;
    color: #8492a5;
  }
  &__pages {
    text-align: center;
    @media screen and (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }
  &__limit-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    color: #8492a5;
  }
  &__limit {
    &.ant-select-focused {
      .ant-select-selector {
        box-shadow: none !important;
      }
      .ant-select-selection-item {
        opacity: 1;
      }
    }
    .ant-select-selector {
      display: flex;
      align-items: center;
      color: #4D7F70;
      border: none !important;
      outline: none;
      box-shadow: none;
    }
    .ant-select-arrow {
      color: #4D7F70;
    }
  }
  .ant-pagination-item-active, .ant-pagination-item-active a, a:hover {
    color: #4D7F70;
    border-color: #4D7F70;
  }
  .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color: #4D7F70;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #F5F8F7;
}
