.swap-wallets-empty {
  max-width: 374px;
  width: 100%;
  margin: 0 auto;
  &__icon {
    svg {
      display: block;
      margin: 0 auto;
    }
  }
  &__text {
    margin-top: 5px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #9B9FB8;
  }
  &__reset {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #679A8A;
    cursor: pointer;
    svg {
      margin-right: 5px;
    }
  }
}