.merchant-item {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 20px 30px;
  background: #ffffff;
  border-radius: 10px;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    padding: 8px 10px;
    justify-content: space-between;
    border-top: 1px solid #e0e6ed;
    border-bottom: 1px solid #e0e6ed;
    border-right: 1px solid #e0e6ed;
    border-radius: 6px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  &__wrapper {
    width: 100%;
    justify-content: space-between !important;
  }
  &__more {
    max-width: 50px !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 17px;
      cursor: pointer;
    }
    &-dropdown {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #d3dce6;

      & > * {
        padding: 15px;
        &:hover {
          background: #2989ef1a;
          cursor: pointer;
        }
        &:first-child {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }
        &:last-child {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
      &-edit {
        color: #818a98;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
      &-delete {
        color: #e85b48;
        &:hover {
          color: #e85b48;
        }
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
    }
    @media screen and (max-width: 768px) {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  &__radio {
    width: 20px !important;
    height: 20px;
    border-radius: 34px;
    border: 1px solid #4D7F70;
    margin-right: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    &-inner {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #4D7F70;
    }
  }
  &__name {
    width: 25%;
    flex-direction: column;
    align-items: flex-start !important;
    & > h2 {
      margin: 0;
      margin-bottom: 2px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }
    & > p {
      margin: 0;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #8492a5;
    }
  }
  &__date_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }
  &__date_creation {
    color: #A0A0A0;
  }
  &__wallets {
    flex-direction: column;
    &-button {
      padding: 7px 17px;
      display: block;
      max-width: 174px;
      width: 100%;
      min-height: 21px;
      background: #4D7F70;
      text-align: center;
      border-radius: 4px;
      text-align: center;
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      line-height: 21px;
      white-space: nowrap;
      &:hover {
        color: #ffffff;
        background: #4D7F70e6;
      }
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      @media screen and (max-width: 768px) {
        padding: 5px 10px;
      }
    }
  }
  &__type {
    color: #4D7F70;
  }
  &__networks-dropdown {
    width: 100%;
    background: #fff;
    padding: 20px 0;
    border: 1px solid #d3dce6;
    margin-top: -9px;
    border-radius: 0 0 6px 6px;
    border-top: 0;
    text-align: center;
    .ant-radio {
      display: none;
    }
    .ant-checkbox-group,
    .ant-radio-group {
      display: flex;
      flex-direction: column;
      max-height: 340px;
      overflow-x: hidden;
      .ant-radio-wrapper {
        margin-right: 0;
        &:hover {
          background: #f2f8ff;
        }
      }
      .ant-checkbox-group-item:not(:first-of-type),
      .ant-radio-wrapper:not(:first-of-type) {
        margin-top: 10px;
      }
    }
  }
  &__networks-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 107px;
    height: 29px;
    padding-left: 15px;
    background: transparent;
    color: #273443;
    font-size: 12px;
    font-weight: 600;
    border-radius: 6px 6px 0 0;
    cursor: pointer;
    border: solid 1px transparent;
    border-bottom: none;
    transition: border-color 0s linear;
    transition-delay: 0.2s;
    &_mainnet {
      color: #4D7F70;
    }
    &_testnet {
      color: #f3ba2f;
    }
    &_hover {
      transition-delay: 0.1s;
      background: inherit;
      border-color: #d9d9d9;
    }
    .anticon {
      margin-top: 2px;
      margin-left: 16px;
    }
    &:disabled {
      display: none;
      background: red;
    }
  }
}

.testnet {
  border-left: 13px solid #f3ba2f;
  @media screen and (max-width: 768px) {
    border-left: 6px solid #f3ba2f;
  }
}
.mainnet,
.testnet {
  border-left: 13px solid #4D7F70;
  @media screen and (max-width: 768px) {
    border-left: 6px solid #4D7F70;
  }
}
