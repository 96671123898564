.swap-wallets {
  padding-bottom: 50px;
  &__button {
    max-width: 790px;
    margin: 20px auto 0;
    display: flex;
    justify-content: flex-end;
    .button {
      min-width: 140px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      padding: 5px 31px;
      height: 42px;
      border-radius: 8px;
    }
    .button:disabled {
      background: #9B9FB8;
      color: #fff;
    }
  }
  &__warning {
    width: 100%;
    max-width: 790px;
    display: flex;
    align-items: center;
    margin: 20px auto 0;
    padding: 16px 20px;
    background: #FDF1D5;
    border: 1px solid #F0B90B;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #3B4857;
    svg {
      margin-right: 10px;
    }

    @media screen and (max-width: 590px) {
      text-align: center;
      padding: 20px;
      svg {
        display: none;
      }
    }
  }
}

.swap-wallets-list {
  margin-top: 10px;
  margin-left: -10px;
  margin-right: -10px;
  overflow: hidden;
  max-height: 10000px;
  // transition: max-height 1s ease;
  &_selected {
    max-height: 70px;
    .swap-wallet-item {
      display: none;
      &_active {
        display: flex;
      }
    }
  }
  &__loader {
    display: block;
    margin: 0 auto;
  }
}