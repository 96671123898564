.swap-new-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 790px;
  margin: 0 auto;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 10px;
  &:not(:first-of-type) {
    margin-top: 20px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-weight: 700;
    font-size: 14px;
    color: #818A98;
  }
  &__button {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #679A8A;
    cursor: pointer;
    svg {
      margin-right: 5px;
    }
  }
}