.swap-empty {
  max-width: 374px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
  &__icon {
    svg {
      display: block;
      margin: 0 auto;
    }
  }
  &__text {
    margin-top: 15px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #9B9FB8;
  }
  &__new {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    width: fit-content;
    margin: 20px auto 0;
    background: #000000;
    border-radius: 8px;
    padding: 11px 24px;
    transition: opacity .3s ease;
    &:hover {
      color: #fff;
      opacity: .9;
    }
  }
  &__reset {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #679A8A;
    cursor: pointer;
    svg {
      margin-right: 5px;
    }
  }
}