.admin-price-chart {
    &__form {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;


        &-wrapper {
            display: flex;
            flex-direction: column;
            position: relative;
        }

        &-error {
            position: absolute;
            top: 42px;
            left: 0;
            color: red;
            margin-left: 5px;
            font-size: 12px;
          }
    }

    &__data-wrapper {
        display: flex;
        width: 70%;
        justify-content: space-between;
    }
}