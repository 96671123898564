.merchant-settings-form {
  &__button {
    width: 100%;
    font-weight: 600;
    border-radius: 4px;
    margin-top: 30px;
  }
  &__text {
    font-size: 12px;
    color: #5f6e80;
  }
  &__comission .ant-input-number {
    width: 100%;
  }
  &__comission {
    margin-bottom: 0;
  }
  &__comission-info {
    display: flex;
    width: 100%;
    padding: 6px 14px;
    margin-top: 20px;
    background: #F9FAFD;
    border-radius: 4px;
    font-size: 12px;
    color: #5F6E80;

    &.individual {
      background-color: transparent;
      margin-top: -20px;
    }
  }
  &__fee-docs {
    display: block;
    font-weight: bold;
    font-size: 12px;
    color: #238AF6;
    margin-top: 16px;
    margin-left: 14px;
  }
  &__payment-methods .ant-checkbox-wrapper-checked,
  &__commission-pays .ant-radio-wrapper-checked {
    color: #4D7F70;
  }
  // &__payment-checkbox-group {
  //   label {
  //     display: block;
  //     &:not(:first-of-type) {
  //       margin-top: 6px;
  //     }
  //   }
  // }
}
