.text {
  display: block;
  text-decoration: none;
  white-space: pre-line;
  &__page-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  &__section-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #273443;
  }
  &__section-description {
    font-size: 12px;
    line-height: 16px;
    color: #5F6E80;
  }
  &__button {
    font-weight: 600;
    font-size: 14px;
  }
  &__gray {
    font-size: 16px;
    line-height: 22px;
    color: #818A98;
  }
  &__gray-bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #818A98;
  }
  &__gray-bold-small {
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    color: #818A98;
  }
  &__dark-bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #3B4857;
  }
  &__label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #273443;
  }
  &__dark-big {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #3B4857;
  }
}